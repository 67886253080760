import React, { useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"

const ImageOpenSuccessStory = ({ arrayData }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className={windowWidth > 1025 ? "cont_images" : "cont_imagesMobile"}>
      <div className="image-containerSStoires">
        {(arrayData.images || []).map((image, index) => (
          <img key={index} src={image} alt={`Image ${index + 1}`} />
        ))}
      </div>
    </div>
  )
}

export default ImageOpenSuccessStory
