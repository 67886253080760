import React, { useContext, useEffect, useState } from "react"
import { LoadingContext } from "../../components/context/LoadingContext"
import angular from "./../../images/home/Experts/Angular.png"
import appium from "./../../images/home/Experts/Appium.png"
import aws from "./../../images/home/Experts/AWS.png"
import azureCloud from "./../../images/home/Experts/Azure cloud.png"
import azure from "./../../images/home/Experts/Azure.png"
import css from "./../../images/home/Experts/Css.png"
import firebase from "./../../images/home/Experts/Firebase.png"
import git from "./../../images/home/Experts/Git.png"
import gitlab from "./../../images/home/Experts/Gitlab.png"
import google from "./../../images/home/Experts/Google.png"
import heroku from "./../../images/home/Experts/Heroku.png"
import html from "./../../images/home/Experts/Html.png"
import ibmAs from "./../../images/home/Experts/Ibm as.png"
import ibmBlue from "./../../images/home/Experts/Ibm blue.png"
import ibm from "./../../images/home/Experts/Ibm.png"
import ionic from "./../../images/home/Experts/Ionic.png"
import java from "./../../images/home/Experts/Java.png"
import js from "./../../images/home/Experts/Javascript.png"
import jenkins from "./../../images/home/Experts/Jenkins.png"
import jmeter from "./../../images/home/Experts/Jmeter.png"
import junit from "./../../images/home/Experts/Junit.png"
import kotlin from "./../../images/home/Experts/Kotlin.png"
import mongo from "./../../images/home/Experts/Mongo.png"
import mysql from "./../../images/home/Experts/Mysql.png"
import net from "./../../images/home/Experts/Net.png"
import node from "./../../images/home/Experts/Node.png"
import nunit from "./../../images/home/Experts/Nunit.png"
import oracle from "./../../images/home/Experts/Oracle.png"
import php from "./../../images/home/Experts/Php.png"
import postgre from "./../../images/home/Experts/Postgre.png"
import python from "./../../images/home/Experts/Python.png"
import reactNative from "./../../images/home/Experts/React native.png"
import reactRed from "./../../images/home/Experts/React redux.png"
import redis from "./../../images/home/Experts/Redis.png"
import ruby from "./../../images/home/Experts/Ruby.png"
import selenium from "./../../images/home/Experts/Selenium.png"
import sqlServer from "./../../images/home/Experts/SQLServer.png"
import svn from "./../../images/home/Experts/Svn.png"
import swift from "./../../images/home/Experts/Swift.png"
import tfs from "./../../images/home/Experts/Tfs.png"
import visualB from "./../../images/home/Experts/Visual basic.png"
import VUE from "./../../images/home/Experts/Vue.png"
import xamarin from "./../../images/home/Experts/Xamarin.png"
import zendesk from "./../../images/home/Experts/Zendesk.png"
import arrowLeft from "./../../images/home/arrowLeft.png"
import arrowRigth from "./../../images/home/arrowRigth.png"
import i18n from "i18next"
import TargetExperts from "../home/TargetExperts"

const TechnologyStack = ({}) => {

  const titleSlider = [
    "Web",
    "Databases",
    "Programming Languages",
    "Mobile / IOS / Android",
    "Cloud / IaaS / Paas / SaaS",
    "Testing",
    "Continuous Integration",
    "Version Control",
  ]

  const elementsSlider = [
    [
      {
        image: js,
        texto: "1",
      },
      {
        image: html,
        texto: "1",
      },
      {
        image: css,
        texto: "1",
      },
      {
        image: angular,
        texto: "1",
      },
      {
        image: VUE,
        texto: "1",
      },
      {
        image: reactRed,
        texto: "1",
      },
      {
        image: ionic,
        texto: "1",
      },
    ],
    [
      {
        image: sqlServer,
        texto: "3",
      },
      {
        image: oracle,
        texto: "3",
      },
      {
        image: mongo,
        texto: "1",
      },
      {
        image: mysql,
        texto: "1",
      },
      {
        image: ibm,
        texto: "1",
      },
      {
        image: postgre,
        texto: "1",
      },
      {
        image: firebase,
        texto: "3",
      },
      {
        image: redis,
        texto: "1",
      },
    ],
    [
      {
        image: net,
        texto: "1",
      },
      {
        image: node,
        texto: "1",
      },
      {
        image: php,
        texto: "1",
      },
      {
        image: java,
        texto: "1",
      },
      {
        image: ibmAs,
        texto: "1",
      },
      {
        image: python,
        texto: "1",
      },
      {
        image: visualB,
        texto: "1",
      },
      {
        image: ruby,
        texto: "1",
      },
    ],
    [
      {
        image: java,
        texto: "1",
      },
      {
        image: swift,
        texto: "3",
      },
      {
        image: kotlin,
        texto: "3",
      },
      {
        image: xamarin,
        texto: "1",
      },
      {
        image: reactNative,
        texto: "1",
      },
      {
        image: ionic,
        texto: "1",
      },
    ],
    [
      {
        image: azureCloud,
        texto: "3",
      },
      {
        image: google,
        texto: "1",
      },
      {
        image: ibmBlue,
        texto: "1",
      },
      {
        image: heroku,
        texto: "1",
      },
      {
        image: firebase,
        texto: "3",
      },
      {
        image: aws,
        texto: "1",
      },
    ],
    [
      {
        image: selenium,
        texto: "1",
      },
      {
        image: appium,
        texto: "1",
      },
      {
        image: nunit,
        texto: "1",
      },
      {
        image: junit,
        texto: "3",
      },
      {
        image: jmeter,
        texto: "3",
      },
    ],
    [
      {
        image: gitlab,
        texto: "1",
      },
      {
        image: azure,
        texto: "1",
      },
      {
        image: jenkins,
        texto: "3",
      },
      {
        image: zendesk,
        texto: "1",
      },
    ],
    [
      {
        image: git,
        texto: "1",
      },
      {
        image: tfs,
        texto: "1",
      },
      {
        image: svn,
        texto: "1",
      },
    ],
  ]

  const [index, setIndex] = useState(0)
  const [titleArr, setTitleArr] = useState(titleSlider[index])
  const [imagesArr, setImagesArr] = useState(elementsSlider[index])
  const { windowWidth } = useContext(LoadingContext)

  useEffect(() => {
    setImagesArr(elementsSlider[index])
    setTitleArr(titleSlider[index])
  }, [index])

  const handleLeftArrowClick = () => {
    const i = index - 1
    if (typeof elementsSlider[i] === "undefined") {
      setIndex(elementsSlider.length - 1)
    } else {
      setIndex(i)
    }
  }

  const handleRightArrowClick = () => {
    const i = index + 1
    if (typeof elementsSlider[i] === "undefined") {
      setIndex(0)
    } else {
      setIndex(i)
    }
  }

  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card pyWork">
      <div className="flip-card-inner2 desktop:flex desktop:ml-16 mobile:ml-4 mt-24">
        <div>
          <p className="subTitleOurServices">
            {i18n.t("home.vacanciesHome.titleExperts")}
          </p>
          <p className="titleOurServices ">
            {i18n.t("TechnologyStack.subtitle")}
          </p>
        </div>

        {windowWidth >= 1025 ? (
          <div className="desktop:flex desktop:pr-12 ml-auto desktop:mr-40 bigdesktop:mr-64">
            <div className="desktop:flex ml-auto desktop:mr-12 mobile:mt-8">
              <button className="cursor-pointer" onClick={handleLeftArrowClick}>
                <img src={arrowLeft} alt="Imagen PopUp" className="mt-1 mr-8" />
              </button>
              <button
                className="cursor-pointer"
                onClick={handleRightArrowClick}
              >
                <img src={arrowRigth} alt="Imagen PopUp" className="" />
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <TargetExperts imagesArr={imagesArr} titleArr={titleArr} />

      {windowWidth < 1025 ? (
        <div className="desktop:flex desktop:pr-12 ml-auto desktop:mr-40 bigdesktop:mr-64 mt-16">
          <div className="desktop:flex ml-auto desktop:mr-12 mobile:mt-8">
            <button className="cursor-pointer" onClick={handleLeftArrowClick}>
              <img src={arrowLeft} alt="Imagen PopUp" className="mt-1 mr-20" />
            </button>
            <button className="cursor-pointer" onClick={handleRightArrowClick}>
              <img src={arrowRigth} alt="Imagen PopUp" className="" />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default TechnologyStack
