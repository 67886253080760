import React, { useContext, useRef } from "react";
import { LoadingContext } from "../context/LoadingContext";
import BVCert from "../../images/home/Certifications/BV-Cert.png";
import CMMICert from "../../images/home/Certifications/CMMI-Cert.png";
import COCert from "../../images/home/Certifications/CO-Cert.png";
import iticCert from "../../images/home/Certifications/itic-Cert.png";
import MarkCert from "../../images/home/Certifications/Mark-Cert.png";
import MPGCert from "../../images/home/Certifications/MPG-Cert.png";
import i18n from "i18next";
import Slider from "react-slick";

const Certification = () => {
  const { windowWidth } = useContext(LoadingContext);
  const carouselRef = useRef(null);

  const imagesArr = [
    { image: COCert, texto: "1" },
    { image: MPGCert, texto: "1" },
    { image: iticCert, texto: "1" },
    { image: MarkCert, texto: "1" },
    { image: CMMICert, texto: "1" },
    { image: BVCert, texto: "1" },
  ];

  const settings = {
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear", 
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          speed: 4000,
        },
      },
    ],
  };

  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerCertification">
      <div className="lineSeparator"></div>
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 mt-24 mb-24">
          <div className="mobile:w-full mobile:pl-16 mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 mobile:pr-16">
            <p className="titleCompanies">{i18n.t("OurExperienceWhyUs.titleCerti")}</p>
          </div>
          <div className="flex targetExperts mt-12 justifyStyle">
            {imagesArr.map((item, i) => (
              <div className="contentTargetExperts" key={i}>
                <div className="m-auto">
                  <img
                    src={item.image}
                    alt="...."
                    className="imgCardCertPart"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flip-card-inner2 mt-24 mb-24">
          <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 mobile:pr-16">
            <p className="titleCompanies">{i18n.t("OurExperienceWhyUs.titleCerti")}</p>
          </div>
          <div className="mt-12 justifyStyle">
            <Slider
              ref={carouselRef}
              {...settings}
              className="rounded-sm mx-2 py-10"
            >
              {imagesArr.map((item, i) => (
                <div className="contentTargetExpertsMobile2" key={i}>
                  <div className="m-auto">
                    <img
                      src={item.image}
                      alt="...."
                      className="imgCardCertification22"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
      <div className="lineSeparator"></div>
    </div>
  );
};

export default Certification;
