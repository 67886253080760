import React, { useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"
import i18n from "i18next"
import VideoBanners from "../shared/VideoBannerAcademy";
import VideoBanners2 from "../shared/VideoBannerCall";
import imageFondo from "../../images/joinOurTeam/Grupo 128.png";

const ScheduleCall = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  const handleRegisterButtonClick = () => {
    // Redirigir a la URL proporcionada
    window.location.href =
      "https://outlook.office365.com/owa/calendar/ExsisDigital@exsis.com.co/bookings/s/jQVs0c6N9UieIz9tMOgLGw2"
  }

  return (
    <>
      {windowWidth >= 1025 ? (
        <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerCall">
          <VideoBanners2 />
          <div className="flip-card-inner2 flex mb-24">

          <div className="ml-4">
          <img src={imageFondo} alt="...."  className="imageCall"/>
          </div>
            <div>
              <p className="titleBannerAcademy contentMediaTeams">
                {i18n.t("pageContactUs.ScheduleCallTitle")}
              </p>
              <button
                className="mobile:mt-4 contentButtomCall"
                style={{ alignSelf: "center" }}
                onClick={handleRegisterButtonClick} 
              >
                <p className="textButtomGetCall">
                  {i18n.t("pageContactUs.ScheduleCallButton")}
                </p>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card ">
          <VideoBanners2 />
          <div className="flip-card-inner2   mt-20 mb-20">
            <div>
              <p className="titleBannerAcademy ml-6">
                {i18n.t("pageContactUs.ScheduleCallTitle")}
              </p>
              <br></br>
              <button
                className="mobile:mt-4 contentButtomCall"
                style={{ alignSelf: "center", width: "90%", margin: "auto" }}
                onClick={handleRegisterButtonClick}
              >
                <p className="textButtonScheduleCall">
                  {i18n.t("pageContactUs.ScheduleCallButton")}
                </p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ScheduleCall
