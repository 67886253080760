import React, { useContext } from 'react';
import { useState, useEffect } from 'react';

import backgroundExbot from "../../../images/Xbot_rectangulomsj.png";
import btnExbot from "../../../images/Xbot_rectangulobtn.png";
import exBot from "../../../images/Xbot.png";
import i18n from '../../i18n';
import WhatsappImage from "../../../images/footer/WhatsAppIcon.png";
import { LoadingContext } from '../../context/LoadingContext';

function Whatsapp  () {
  const { windowWidth } = useContext(LoadingContext)

	return (
		<div className={windowWidth>1025?"whatsapp-icon desktop:mt-72":"whatsapp-icon desktop:mt-72 mr-6"}>
			<a href="https://wa.me/+573176448607" target="_blank" rel="noopener noreferrer">
			<img src={WhatsappImage} alt="whatsapp"/>
			</a>  
        </div>
		
	);
};

export default Whatsapp;