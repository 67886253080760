import React, { Fragment,useContext } from "react";
import { useState } from "react";
import i18n from "i18next";
import ScrollTrigger from "react-scroll-trigger";
import { Link } from "gatsby";

// components
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";

// images

import logoFooter from "../../../images/footer/LogoFooter.png";
import facebookF from "../../../images/footer/facebookFooter.png";
import xF from "../../../images/footer/xFooter.png";
import youtubeF from "../../../images/footer/youtubeFooter.png";
import linkedinF from "../../../images/footer/linkedinFooter.png";
import instagramF from "../../../images/footer/instaFooter.png";
import tiktokF from "../../../images/footer/tiktokF.png"
import { LoadingContext } from '../../../components/context/LoadingContext';

const Footer = () => {
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const { windowWidth } = useContext(LoadingContext);
  function onEnterViewport() {
    setTimeout(() => {
      document
        .getElementById("logo-animated")
        .classList.add("xlogo__image--animation");
    }, 500);
  }

  function onExitViewport() {
    document
      .getElementById("logo-animated")
      .classList.remove("xlogo__image--animation");
  }

  return (
    <Fragment>
     
      <footer className="w-full flex flex-wrap border-grey pin-b bg-image image-footer-degraded image-footer-degraded-movil text-white -mt-1 mobile:py-24 mobile:px-8 landscape:pt-20 desktop:pr-32 desktop:pl-20 landscape:pb-32">
        <div className="w-full  landscape:text-left">
          <div className="flex justify-between flex-wrap mb-8">
           {windowWidth >= 1025 ?
            <div className={windowWidth>1700 ? "mobile:mr-0 landscape:mr-3 content1Footer" : "mobile:mr-0 landscape:mr-3 content1FooterLaptp"}>
                <img className="footerLogo" src={logoFooter} alt="logo exsis" />
                  <div className="flex mt-4 ml-4">
                    <a
                            href="https://www.facebook.com/Exsisdigital/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={facebookF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://www.linkedin.com/company/exsis-digital"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={linkedinF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://www.instagram.com/exsisdigital/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={instagramF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://www.youtube.com/@exsisdigital"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={youtubeF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://twitter.com/Exsisdigital"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={xF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://www.tiktok.com/@exsisdigital"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={tiktokF} alt="logo exsis" />
                    </a>
                  </div>
                  <p className="mt-4 textLogoFooter">
                    {i18n.t("home.footer.text1")}
                  </p>
                  <p className="mt-4 textLogoFooter">
                    {i18n.t("home.footer.text2")}
                  </p>
                  <p className="mt-4 textLogoFooter">
                    {i18n.t("home.footer.text3")}
                  </p>
                  <p className="mt-4 textLogoFooter">
                    {i18n.t("home.footer.text4")}
                  </p>
                  <p className={windowWidth >1700 ? "mt-4 textLogoFooterMX": "mt-4 textLogoFooter"}>
                    {i18n.t("home.footer.text5")}
                  </p>
              </div>
              :
              <div className="mobile:mr-0 landscape:mr-3 content1Footer">
                  <img className="footerLogo" src={logoFooter} alt="logo exsis" />
                    <div className="flex mt-12 mb-8">
                    <a
                            href="https://www.facebook.com/Exsisdigital/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={facebookF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://www.linkedin.com/company/exsis-digital"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={linkedinF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://www.instagram.com/exsisdigital/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={instagramF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://www.youtube.com/@exsisdigital"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={youtubeF} alt="logo exsis" />
                    </a>
                    <a
                            href="https://twitter.com/Exsisdigital"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                    <img className="mr-4 cursor-pointer" src={xF} alt="logo exsis" />
                    </a>
                    </div>
                    <p className="mt-8 textLogoFooter">
                    {i18n.t("home.footer.text1")}
                    </p>
                    <p className="mt-8 textLogoFooter">
                    {i18n.t("home.footer.text2")}
                    </p>
                    <p className="mt-8 textLogoFooter">
                    {i18n.t("home.footer.text3")}
                    </p>
                    <p className="mt-8 textLogoFooter">
                    {i18n.t("home.footer.text4")}
                    </p>
                    <p className="mt-8 textLogoFooter mb-8">
                    {i18n.t("home.footer.text5")}
                    </p>
              </div>
              }

              {/* SERVICES */}
              <div className={windowWidth>1700? "mobile:mr-0 landscape:mr-3 mobile:w-full landscape:w-auto content2Footer":"mobile:mr-0 landscape:mr-3 mobile:w-full landscape:w-auto content2FooterLaptp"}>
                <Link to="/service-expansion">
                  <p className="mb-3 landscape:block titleSectionFooter">{i18n.t("home.footer.title")}</p>
                  </Link>
                  <Link to="/service-expansion" className="block linksSectionFooter">
                  {i18n.t("home.footer.textService1")}
                  </Link>
                  <Link to="/service-expansion" className="block linksSectionFooter">
                  {i18n.t("home.footer.textService2")}
                  </Link>
                  <Link to="/service-expansion" className="block linksSectionFooter">
                  {i18n.t("home.footer.textService3")}
                  </Link>
                  <Link to="/service-expansion" className="block linksSectionFooter">
                  {i18n.t("home.footer.textService4")}
                  </Link>
                  <Link to="/service-expansion" className="block linksSectionFooter">
                  {i18n.t("home.footer.textService5")}
                  </Link>
                  <Link to="/service-expansion" className="block linksSectionFooter">
                  {i18n.t("home.footer.textService6")}
                  </Link>
                  <Link to="/service-expansion" className="block linksSectionFooter">
                  {i18n.t("home.footer.textService7")}
                  </Link>
                  <Link to="/service-expansion" className="block linksSectionFooter">
                  {i18n.t("home.footer.textService8")}
                  </Link>
              </div> 

            {/* IT TALENT AND XMEDIA*/}
            <div className={windowWidth >1700 ? "footer-further mobile:mr-0 landscape:mr-3 mobile:w-full landscape:w-auto content3Footer":"footer-further mobile:mr-0 landscape:mr-3 mobile:w-full landscape:w-auto content3FooterLaptp"}>
              <Link to="/exclusive-products">
              <p className="mb-3 landscape:block titleSectionFooter">{i18n.t("home.footer.title2")}</p>
              </Link>
                <Link to="/exclusive-products" className="block linksSectionFooter">
                {i18n.t("home.footer.textIT1")}
                </Link>
                <Link to="/exclusive-products" className="block linksSectionFooter">
                {i18n.t("home.footer.textIT2")}
                </Link>
                <Link to="/exclusive-products" className="block linksSectionFooter">
                {i18n.t("home.footer.textIT3")}
                </Link>
                <Link to="/exclusive-products" className="block linksSectionFooter">
                {i18n.t("home.footer.textIT4")}
                </Link>
                <Link to="/exclusive-products" className="block linksSectionFooter">
                {i18n.t("home.footer.textIT5")}
                </Link>


                <Link to="/media-blog">
                <p className="mb-3  landscape:block titleSectionFooter mt-8">X-Media</p>
                </Link>
                  <Link to="/media-blog" className="block linksSectionFooter">
                  Blog
                  </Link>
                  
                {i18n.language == "es" ?
                  <Link to="/x-media-podcast" className="block linksSectionFooter">
                  Podcasts
                  </Link>:null            
                  }  
                  <Link to="/x-media-success-stories" className="block linksSectionFooter">
                    {i18n.t("home.footer.textSuccess")}
                  </Link> 
            </div>

            {/* Terms and Policy */}
            <div className={windowWidth >1700? "contactUs mobile:w-full landscape:w-auto content4Footer": "contactUs mobile:w-full landscape:w-auto content4FooterLptp"}>
                <Link to="/" className="block linksSectionFooter">
                  {i18n.t("home.footer.textTerms")}
                </Link>
                <Link to="/" className="block linksSectionFooter">
                  {i18n.t("home.footer.textPolicy")}
                </Link>        
                
                <Link to="/" className={windowWidth > 1025 ? "block linksSectionFooter mt-24 pt-32": "block linksSectionFooter mt-12 pt-3"}>
                  {i18n.t("home.footer.textCopyR")}
                </Link>
            </div>

          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;