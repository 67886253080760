import React, { useEffect, useState, useContext } from "react"
import i18n from "i18next"
import { Link } from "gatsby"
import NavBarMobile from "./NavBarMobile"
import NavItemService from "./NavItemService"
import NavItem from "./NavItem"
import Button from "./ButtonLanguage"
import ButtonGetInTouch from "./ButtonGetInTouch"
import ExsisLogo from "../../../images/navBar/LogocolorExsis.jpg"
import ExsisBirthday from "../../../images/navBar/logo30YearsExsisFit.png"
import { LoadingContext } from "../../context/LoadingContext"

import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

const NavBar = () => {
  const [loadMobile, setLoadMobile] = useState(null)
  const { windowWidth } = useContext(LoadingContext)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const [anchorElMedia, setAnchorElMedia] = React.useState(null)
  const openMedia = Boolean(anchorElMedia)

  const [pathname, setPathname] = useState()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickMedia = event => {
    setAnchorElMedia(event.currentTarget)
  }

  useEffect(() => {
    windowWidth < 1078 ? setLoadMobile(true) : setLoadMobile(false)
  }, [windowWidth])

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  function closeNavbar() {
    setAnchorEl(null)
  }

  function closeMedia() {
    setAnchorElMedia(null)
  }

  return (
    <>
      <NavBarMobile pathname={pathname} />
      <header className="header-navbar navBarDesktop">
      <div className={windowWidth > 1700 ? "logoExsis navSetDesk" : "logoExsis navSetLapt"}>
          <Link to={"/"}>
            <img className="" src={ExsisBirthday} alt="Exsis logo" />
          </Link>
        </div>
        <div className={windowWidth >1700 ? "cont-navbarDesktop ml-12 mr-12" : "cont-navbarDesktopLaptp ml-12 mr-12"}>
          <nav
            className="flex justify-center pb-1 pt-1"
            style={{ alignSelf: "center" }}
          >
            <div className="mt-auto mb-auto cursor-pointer">
              <Link to={"/"}>
                <p
                  className={
                    pathname === "/" 
                    ? (windowWidth > 1700 
                        ? "titlerSelectMenu" 
                        : "titlerSelectMenu titleNavLaptp"
                      ) 
                    : (windowWidth > 1700 
                        ? "titleSectionNavBar" 
                        : "titleSectionNavBar titleNavLaptp"
                      )
                  }
                >
                  {i18n.t("home.menu.seccion1")}
                </p>
              </Link>
            </div>

            <div className="ml-8 mt-auto mb-auto cursor-pointer">
              <div className="flex" onClick={e => handleClick(e)}>
                {i18n.language == "en" ? (
                  <>
                    <p
                      className={
                        (pathname == "/our-Experience" || pathname == "/exsis-academy")
                        ? (windowWidth > 1700 
                            ? "titleSectionNavBar2Select" 
                            : "titleSectionNavBar2Select titleNavLaptp"
                          ) 
                        : (windowWidth > 1700 
                            ? "titleSectionNavBar2" 
                            : "titleSectionNavBar2 titleNavLaptp"
                          )
                      }
                    >
                      E
                    </p>
                    <p
                      className={
                        (pathname == "/our-Experience" || pathname == "/exsis-academy")
                        ? (windowWidth > 1700 
                            ? "titleSectionNavBarBlueSelect" 
                            : "titleSectionNavBarBlueSelect titleNavLaptp"
                          ) 
                        : (windowWidth > 1700 
                            ? "titleSectionNavBarBlue" 
                            : "titleSectionNavBarBlue titleNavLaptp"
                          )
                      }
                    >
                      x
                    </p>
                    <p
                      className={
                        (pathname == "/our-Experience" || pathname == "/exsis-academy")
                        ? (windowWidth > 1700 
                            ? "titleSectionNavBar2Select" 
                            : "titleSectionNavBar2Select titleNavLaptp"
                          ) 
                        : (windowWidth > 1700 
                            ? "titleSectionNavBar2" 
                            : "titleSectionNavBar2 titleNavLaptp"
                          )
                      }
                    >
                      perience{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p
                      className={
                        (pathname == "/our-Experience" || pathname == "/exsis-academy")
                        ? (windowWidth > 1700 
                            ? "titleSectionNavBar2Select" 
                            : "titleSectionNavBar2Select titleNavLaptp"
                          ) 
                        : (windowWidth > 1700 
                            ? "titleSectionNavBar2" 
                            : "titleSectionNavBar2 titleNavLaptp"
                          )
                      }
                    >
                      E
                    </p>
                    <p className={windowWidth > 1700 ? "titleSectionNavBarBlue" : "titleSectionNavBarBlue titleNavLaptp"}>x</p>
                    <p
                      className={
                        (pathname == "/our-Experience" || pathname == "/exsis-academy")
                        ? (windowWidth > 1700 
                            ? "titleSectionNavBar2Select" 
                            : "titleSectionNavBar2Select titleNavLaptp"
                          ) 
                        : (windowWidth > 1700 
                            ? "titleSectionNavBar2" 
                            : "titleSectionNavBar2 titleNavLaptp"
                          )
                      }
                    >
                      periencia{" "}
                    </p>
                  </>
                )}
              </div>

              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={closeNavbar}
              >
                <Link to={"/our-Experience"}>
                  <MenuItem onClick={closeNavbar}>
                    <p className={windowWidth > 1700 ? "titleSectionNavBar cursor-pointer" : "titleSectionNavBar cursor-pointer titleNavLaptp"}>
                      {i18n.t("home.menu.seccion8")}
                    </p>
                  </MenuItem>
                </Link>
                <Link to={"/exsis-academy"}>
                  <MenuItem onClick={closeNavbar}>
                    <p className={windowWidth > 1700 ? "titleSectionNavBar cursor-pointer" : "titleSectionNavBar cursor-pointer titleNavLaptp"}>
                      {i18n.t("home.menu.seccion9")}
                    </p>
                  </MenuItem>
                </Link>
              </Menu>
            </div>

            <div className="ml-8 mt-auto mb-auto cursor-pointer">
              <Link to={"/service-expansion"}>
                <p
                  className={
                    (pathname === "/service-expansion" ||
                      pathname === "/services-software" ||
                      pathname === "/services-hunting" ||
                      pathname === "/services-blockchain") 
                       ? (windowWidth > 1700 
                           ? "titlerSelectMenu" 
                           : "titlerSelectMenu titleNavLaptp"
                         )
                       : (windowWidth > 1700 
                           ? "titleSectionNavBar" 
                           : "titleSectionNavBar titleNavLaptp"
                         )
                  }
                >
                  {i18n.t("home.menu.seccion2")}{" "}
                </p>
              </Link>
            </div>

            <div className="ml-8 mt-auto mb-auto cursor-pointer">
              <Link to={"/exclusive-products"}>
                <p
                  className={
                    (pathname === "/exclusive-products" ||
                      pathname === "/TalentATS" ||
                      pathname === "/agile-team" ||
                      pathname === "/transformation")
                       ? (windowWidth > 1700 
                           ? "titlerSelectMenu" 
                           : "titlerSelectMenu titleNavLaptp"
                         )
                       : (windowWidth > 1700 
                           ? "titleSectionNavBar" 
                           : "titleSectionNavBar titleNavLaptp"
                         )
                  }
                >
                  {i18n.t("home.menu.seccion3")}{" "}
                </p>
              </Link>
            </div>

            <div className="ml-8 mt-auto mb-auto cursor-pointer">
              <Link to={"/exceptional-careers"}>
                <p
                  className={
                    pathname === "/exceptional-careers" 
                  ? (windowWidth > 1700 
                      ? "titlerSelectMenu" 
                      : "titlerSelectMenu titleNavLaptp"
                    )
                  : (windowWidth > 1700 
                      ? "titleSectionNavBar" 
                      : "titleSectionNavBar titleNavLaptp"
                    )
                  }
                >
                  {i18n.t("home.menu.seccion4")}{" "}
                </p>
              </Link>
            </div>

            <div className="ml-8 mt-auto mb-auto flex">
              <div
                className="flex cursor-pointer"
                onClick={e => handleClickMedia(e)}
              >
                <p
                  className={
                    (pathname === "/media-blog" ||
                      pathname === "/x-media-success-stories" ||
                      pathname === "/x-media-podcast")
                       ? (windowWidth > 1700 
                           ? "titleSectionNavBarBlueSelect" 
                           : "titleSectionNavBarBlueSelect titleNavLaptp"
                         )
                       : (windowWidth > 1700 
                           ? "titleSectionNavBarBlue" 
                           : "titleSectionNavBarBlue titleNavLaptp"
                         )
                  }
                >
                  X
                </p>
                <p
                  className={
                    (pathname === "/media-blog" ||
                      pathname === "/x-media-success-stories" ||
                      pathname === "/x-media-podcast")
                       ? (windowWidth > 1700 
                           ? "titleSectionNavBar2Select ml-2" 
                           : "titleSectionNavBar2Select ml-2 titleNavLaptp"
                         )
                       : (windowWidth > 1700 
                           ? "titleSectionNavBar2 ml-2" 
                           : "titleSectionNavBar2 ml-2 titleNavLaptp"
                         )
                  }
                >
                  Media{" "}
                </p>
              </div>

              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorElMedia}
                open={openMedia}
                onClose={closeMedia}
              >
                <Link to={"/media-blog"}>
                  <MenuItem onClick={closeMedia}>
                    <p className={windowWidth > 1700 ? "titleSectionNavBar cursor-pointer" : "titleSectionNavBar cursor-pointer titleNavLaptp"}>
                      {i18n.t("home.menu.seccion5")}
                    </p>
                  </MenuItem>
                </Link>
                <Link to={"/x-media-success-stories"}>
                  <MenuItem onClick={closeMedia}>
                    <p className={windowWidth > 1700 ? "titleSectionNavBar cursor-pointer" : "titleSectionNavBar cursor-pointer titleNavLaptp"}>
                      {i18n.t("home.menu.seccion6")}
                    </p>
                  </MenuItem>
                </Link>
                {i18n.language == "es" ? (
                  <Link to={"/x-media-podcast"}>
                    <MenuItem onClick={closeMedia}>
                      <p className={windowWidth > 1700 ? "titleSectionNavBar cursor-pointer" : "titleSectionNavBar cursor-pointer titleNavLaptp"}>
                        {i18n.t("home.menu.seccion7")}
                      </p>
                    </MenuItem>
                  </Link>
                ) : null}
              </Menu>
            </div>

            <ButtonGetInTouch />
            <Button />
          </nav>
        </div>
      </header>
    </>
  )
}

export default NavBar
