import React, { Fragment, useState, useContext,useRef } from "react"
import image1 from "../../images/products/Grupo 476.png"
import { LoadingContext } from "../context/LoadingContext"
import Slider from "react-slick";

import i18n from "i18next"
import BVCert from "../../images/home/Certifications/BV-Cert.png";
import CMMICert from "../../images/home/Certifications/CMMI-Cert.png";
import COCert from "../../images/home/Certifications/CO-Cert.png";
import iticCert from "../../images/home/Certifications/itic-Cert.png";
import MarkCert from "../../images/home/Certifications/Mark-Cert.png";
import MPGCert from "../../images/home/Certifications/MPG-Cert.png";

const BannerExclusiveProducts = ({ bannerImageURL }) => {
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)
  const carouselRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          speed: 4000,
        },
      },
    ],
  };


  const imagesArr = [
    { image: COCert, texto: "1" },
    { image: MPGCert, texto: "1" },
    { image: iticCert, texto: "1" },
    { image: MarkCert, texto: "1" },
    { image: CMMICert, texto: "1" },
    { image: BVCert, texto: "1" },
  ];

  return (
    <Fragment>
      <div className="container-banner-mainProducts">
        <div className="">
          {windowWidth >= 1025 ? (
            <div className="flex mobile:flex-col-reverse desktop:flex-row ">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="shared-banner-content-btnAcademy m-4 desktop:mt-4 mobile:mt-16 pTalent"
              >
                <div className="text-left textTitleTalent mb-32 tablet:mb-0">
                  <p className="text-56 text-center desktop:text-64 bigdesktop:text-80 mt-4 font-byom-bold">
                  {i18n.t("textServices.textNew84")}
                  </p>
                </div>

                <div className="text-left text-center text-white mb-32 tablet:mb-0">
                  <img
                    src={image1}
                    alt="Imagen PopUp"
                    className="ml-auto mr-auto mt-8"
                  />
                </div>

                <div className="mt-8">
                  <p className="bannerTalentText">
                  {i18n.t("textServices.textNew90")}
                  </p>

                </div>

                <div className="flex mt-24 justifyStyle">
                  {imagesArr.map((item, i) => (
                    <div className="" key={i}>
                      <div className="m-auto">
                        <img
                          src={item.image}
                          alt="...."
                          className="imgCardCertPart"
                        />
                      </div>
                    </div>
                  ))}

                </div>

                <div>

                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="ml-5 tablet:mx-4 mobile:my-48 text-white"
              >

                <div className="text-left textTitleTalent mb-32 tablet:mb-0">
                  <p className="text-56 text-center desktop:text-64 bigdesktop:text-80 mt-4 font-byom-bold">
                  {i18n.t("textServices.textNew84")}
                  </p>
                </div>

                <div className="text-left text-center text-white mb-32 tablet:mb-0">
                  <img
                    src={image1}
                    alt="Imagen PopUp"
                    className="ml-auto mr-auto mt-8"
                  />
                </div>

                <div className="mt-8">
                  <p className="bannerTalentTextMobile">
                  {i18n.t("textServices.textNew90")}
                  </p>

                </div>

                <div className="mt-12 justifyStyle">
            <Slider
                    ref={carouselRef}
                    {...settings}
                    className="rounded-sm mx-2 py-10"
                  >
                    {imagesArr.map((item, i) => (
                      <div className="contentTargetExpertsMobile2" key={i}>
                        <div className="m-auto">
                          <img
                            src={item.image}
                            alt="...."
                            className="imgCardCertification22"
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>

              </div>
            </div>
          )}


        </div>
      </div>
    </Fragment>
  )
}

BannerExclusiveProducts.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
}

export default BannerExclusiveProducts
