import React, { useContext, useRef } from 'react';
import i18n from "i18next";
import { LoadingContext } from '../../components/context/LoadingContext';
import aws from '../../images/exsisAcademy/AWS.png';
import blockchain from '../../images/exsisAcademy/BLOCKCHAIN.png';
import solidity from '../../images/exsisAcademy/SOLIDITY.png';
import expert from '../../images/exsisAcademy/EXPERT.png';
import Slider from "react-slick";

const CertificationsAcademy = () => {
  const { windowWidth } = useContext(LoadingContext);
  const carouselRef = useRef(null);

  const imagesArr = [
    { image: aws, texto: "AWS Cloud Practioner Essentials" },
    { image: blockchain, texto: "Blockchain" },
    { image: solidity, texto: "Solidity Developer" },
    { image: expert, texto: "Hyperledger Expert" },
  ];

  const settings = {
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          speed: 4000,
        },
      },
    ],
  };

  return (
    <div className="mobile:w-full desktop:pl-4 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerCertification">
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 mt-24 mb-24">
          <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4">
            <p className="titleCompanies">{i18n.t("ExsisAcademy.titleCerti")}</p>
          </div>
          <div className="flex targetExperts mt-12 justifyStyle">
            {imagesArr.map((item, i) => (
              <div className="contentTargetCertifications" key={i}>
                <div className="m-auto boxFlex">
                  <img
                    src={item.image}
                    alt="...."
                    className="mr-auto ml-auto mt-4"
                  />
                  <p className="textCertificationAcademy m-8 mt-4">{item.texto}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flip-card-inner2 mt-24 mb-24">
          <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4">
            <p className="titleCompanies">{i18n.t("ExsisAcademy.titleCerti")}</p>
          </div>
          <div className="mt-12 justifyStyle">
            <Slider
              ref={carouselRef}
              {...settings}
              className="rounded-sm mx-2 py-10"
            >
              {imagesArr.map((item, i) => (
                <div className="contentTargetAcademyMobile2" key={i}>
                  <div className="m-auto">
                    <img
                      src={item.image}
                      alt="...."
                      className="imgCardCertification"
                    />
                    <p className="textCertificationAcademy m-4 mt-4">{item.texto}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificationsAcademy;
