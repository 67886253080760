import React, { Component } from "react";
import i18n from "i18next";
import Slider from "react-slick";
import Slide from "../shared/certifications/Slide";

export default class CustomerSlide extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 4000,
      cssEase: "linear",
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 4000,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 3000,
          },
        },
      ],
    };

    return (
      <div className="px-8 mobile:mt-10 desktop:mt-16 boxCertification">
        <div className="-mx-8">
          <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4">
            <p className="titleCompanies">
              {i18n.t("home.ourServices.titleCompanies")}
            </p>
            <p className="titleCompanies">
              {i18n.t("home.ourServices.titleCompanies2")}
            </p>
            <p className="titleCompanies text-blue-100">
              {i18n.t("home.ourServices.titleCompanies3")}
            </p>
          </div>
        </div>

        <div className="flex flex-wrap -mx-8">
          <div className="mobile:w-full landscape:w-full tablet:w-full desktop:w-full pr-8 pl-4">
            <Slider {...settings}>
              {this.props.items.map((item, key) => (
                <div key={key}>
                  <Slide image={item} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
