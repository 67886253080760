import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/products/mockup-2.3.png"
import image2 from "../../images/products/mockup1.2.png"
import image3 from "../../images/products/person-working-html-computer.png"
import image4 from "../../images/servicesExpansion/Grupo334.png"
import { Link } from "gatsby"


const ExstaffInfo = ({ }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div
      className={windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card "}
    >

      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 p-16">
          <div className="flex">
            <div className="tech-section">


              <div className="mt-8">
                <p className="textTalentNew mt-4">
                {i18n.t("textServices.textNew97")}
                </p>

                <p className="textTalentNew mt-4">
                {i18n.t("textServices.textNew98")}
                </p>

              </div>

              <div className="contentViewMoreProducts mt-40">
                <Link to="/TalentATS">
                  <button
                    className="mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                    style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                  >
                    <p className="textBottomAgendProducts">
                    {i18n.t("textServices.textNew27")}
                    </p>
                  </button>
                </Link>
              </div>

            </div>
            <div className="mt-10 tech-section justify-center w-1/2 mx-auto">


              <div className="flex">
                <img src={image1} alt="..." className="w-1/2" />
                <img src={image2} alt="..." className="w-1/2 " />

              </div>

            </div>


          </div>

        </div>
      ) : (
        <div className="flip-card-inner2">
          
          <div className="">
          <p className="textTalentNew mt-4">
                {i18n.t("textServices.textNew97")}
                </p>

                <p className="textTalentNew mt-4">
                {i18n.t("textServices.textNew98")}
                </p>
              </div>

          <div className="mt-10 ">
            <img src={image1} alt="...." className="ml-auto mr-auto" />
            <img src={image2} alt="...." className="ml-auto mr-auto" />
          </div>

          <div className="my-4">
            <Link to="/TalentATS">
              <button
                className="w-full mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
              >
                <p className="textBottomAgendProducts">
                {i18n.t("textServices.textNew27")}
                </p>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default ExstaffInfo
