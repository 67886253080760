import React from "react"
import i18n from "i18next"
import avianca from "./../../images/home/Customers/avianca.png"
import ilumno from "./../../images/home/Customers/ilumno.png"
import bbva from "./../../images/home/Customers/bbva.png"
import chubb from "./../../images/home/Customers/chubb.png"
import dian from "./../../images/home/Customers/dian.png"
import alcaldia from "./../../images/home/Customers/alcaldia-de-bogotá.png"
import axaC from "./../../images/home/Customers/axa-colpatria.png"
import bansat from "./../../images/home/Customers/bansat.png"
import cognizant from "./../../images/home/Customers/cognizant.png"
import dafiti from "./../../images/home/Customers/dafiti.png"
import elanco from "./../../images/home/Customers/elanco.png"
import gapsi from "./../../images/home/Customers/gapsi.png"
import incluit from "./../../images/home/Customers/incluit.png"
import intelligents from "./../../images/home/Customers/intelligents-tecnologis.png"
import kpg from "./../../images/home/Customers/kpg.png"
import minihacienda from "./../../images/home/Customers/minihacienda.png"
import prosperidad from "./../../images/home/Customers/prosperidad-social.png"
import seguros from "./../../images/home/Customers/seguros-del-estado.png"
import syntelliu from "./../../images/home/Customers/syntelliu.png"
import tech from "./../../images/home/Customers/tech-mahindra.png"
import tgi from "./../../images/home/Customers/tgi.png"
import uala from "./../../images/home/Customers/uala.png"
import wamdigital from "./../../images/home/Customers/wamdigital.png"
import evertec from "./../../images/home/Customers/evertec.png"
import embajada from "./../../images/home/Customers/embajada-de-australia.png"
import CustomerslideSF from "../ourDNA/CustomerSlideSF"

const group4 = [bbva, ilumno, chubb, dian, avianca, alcaldia, axaC, bansat, cognizant, dafiti, 
  elanco, gapsi, incluit, intelligents, kpg, minihacienda, prosperidad, seguros, syntelliu, tech,
  tgi, uala, wamdigital, evertec, embajada]

const CustomersSF = () => {
  const attributesMainTitle = {
    id: "CustomersDNA",
    title: i18n.t("pageourdna.customer"),
    styles: {
      bulletColor: "light-blue",
      textColor: "dark-blue",
      fontSizeMobile: "36",
      fontSize: "44",
      alignMobile: "left",
      alignDesktop: "center",
      marginL: "20",
    },
  }

  return (
    <div className="customers__container">
      <CustomerslideSF slidesToShowMobile={1} items={group4} />
    </div>
  )
}

export default CustomersSF
