import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/products/women-office-discussing-work-process.png"
import image2 from "../../images/products/close-up-businessman-hand-using-creative-business-chart-hologram-blurry-city-background-innovation-finance-trade-future-ai-technology-concept-double-e.png"
import image3 from "../../images/products/brainstorming-business-people-whiteboard-with-postit-notes-diversity-planning-collaboration-finance-deal-ideas-marker-moodboard-teamwork-strategy-corp.png"
import image4 from "../../images/servicesExpansion/Grupo334.png"
import { Link } from "gatsby"
import xElement from "../../images/servicesExpansion/Grupo332.png"
import xElement1 from "../../images/servicesExpansion/Grupo344.png"
import image7 from "../../images/products/young-beautiful-businesswoman-writing-marketing-idea-glass-wall-smart-female-leader-thinking-presenting-business-strategy-while-expert-investor-listen.png"

const AgileTransformationInfo = ({ }) => {
    const { windowWidth } = useContext(LoadingContext)

    return (
        <div
            className={windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card "}
        >

            {windowWidth >= 1025 ? (
                <div className="flip-card-inner2 p-16">
                    <div className="flex">
                        <div className="tech-section">


                            <div className="mt-8">
                                <p className="textTalentNew mt-4">
                                    {i18n.t("textServices.textNew101")}
                                </p>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 1" className="separator-image" />
                                    <p className="textTalentNew ml-4">
                                        {i18n.t("textServices.textNew102")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 2" className="separator-image" />
                                    <p className="textTalentNew ml-4">
                                        {i18n.t("textServices.textNew103")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 3" className="separator-image" />
                                    <p className="textTalentNew ml-4">
                                        {i18n.t("textServices.textNew104")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textTalentNew ml-4">
                                        {i18n.t("textServices.textNew105")}
                                    </p>
                                </div>

                            </div>

                            <div className="contentViewMoreProducts mt-12">
                                <Link to="/transformation">
                                    <button
                                        className="mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                                        style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                                    >
                                        <p className="textBottomAgendProducts">
                                            {i18n.t("textServices.textNew27")}
                                        </p>
                                    </button>
                                </Link>
                            </div>

                        </div>
                        <div className="mt-6 tech-section justify-center w-1/2 mx-auto">

                            <div className="">

                                <img src={image1} alt="..." className="" />

                                <div className="flex">
                                    <div className="inline-block">
                                        <img src={image2} alt="..." className="w-full " />
                                    </div>
                                    <div className="inline-block">
                                        <img src={image3} alt="..." className="w-full " />
                                    </div>
                                    <div className="inline-block">
                                        <img src={image7} alt="..." className="w-full " />
                                    </div>
                                </div>

                            </div>

                            <div className="flex w-full justify-end pr-32">
                                <img className="w-1/3 desktop:w-fit styleXelementTalent XelementTransfo" src={xElement1} alt="xs" />
                            </div>

                            <div className="flex w-full justify-start">
                                <img className="w-1/3 desktop:w-fit styleXelementTalent XelementTransfo2" src={xElement} alt="xs" />
                            </div>

                        </div>


                    </div>

                </div>
            ) : (
                <div className="flip-card-inner2">

                    <div className="">
                        <p className="textTalentNew mt-4">
                            {i18n.t("textServices.textNew101")}
                        </p>

                        <div className="mt-6 flex items-center">
                            <img src={image4} alt="Índice 1" className="separator-image" />
                            <p className="textTalentNew ml-4">
                                {i18n.t("textServices.textNew102")}
                            </p>
                        </div>

                        <div className="mt-6 flex items-center">
                            <img src={image4} alt="Índice 2" className="separator-image" />
                            <p className="textTalentNew ml-4">
                                {i18n.t("textServices.textNew103")}
                            </p>
                        </div>

                        <div className="mt-6 flex items-center">
                            <img src={image4} alt="Índice 3" className="separator-image" />
                            <p className="textTalentNew ml-4">
                                {i18n.t("textServices.textNew104")}
                            </p>
                        </div>

                        <div className="mt-6 flex items-center">
                            <img src={image4} alt="Índice 4" className="separator-image" />
                            <p className="textTalentNew ml-4">
                                {i18n.t("textServices.textNew105")}
                            </p>
                        </div>

                    </div>

                    <div className="mt-10 ">
                        <img src={image1} alt="...." className="ml-auto mr-auto" />
                        <img src={image2} alt="...." className="ml-auto mr-auto" />
                        <img src={image3} alt="...." className="ml-auto mr-auto" />
                        <img src={image7} alt="...." className="ml-auto mr-auto" />
                    </div>

                    <div className="my-4">
                        <Link to="/transformation">
                            <button
                                className="w-full mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                                style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                            >
                                <p className="textBottomAgendProducts">
                                    {i18n.t("textServices.textNew27")}
                                </p>
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AgileTransformationInfo
