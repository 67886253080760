import React from 'react';
import bgVideoBanners from '../../images/home/bgBannersLanding.mp4';

const VideoBanners = ({ children }) => {
  return (
    <div className="video-background-containerBanners">
      <video autoPlay loop muted playsInline className="banners-video">
        <source src={bgVideoBanners} type="video/mp4" />
        ...
      </video>
    </div>
  );
};

export default VideoBanners;
