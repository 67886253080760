import React, { Fragment, useState, useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"
import VideoBanners from "../shared/VideoBanners";

function BannerServicesHunting({ image, TextComponent}) {
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)

  return (
    <Fragment>
      <div className="container-banner-mainProducts">
        <div className="shared-banner-container">
          <div
            className={`flex mobile:flex-col-reverse desktop:flex-row ${
              windowWidth >= 1025 ? "bannerPrincipal" : "bannerPrincipalMobile"
            }`}
          >
                  <VideoBanners />
            <div
              ref={el => {
                if (el) {
                  const resizeObserver = new ResizeObserver(entries => {
                    setDinamicHieghtColbanner(entries[0].contentRect.height)
                  })
                  resizeObserver.observe(el)
                }
              }}
              className="tablet:flex-1 tablet:self-center m-4 ml-0 desktop:mt-4 tablet:ml-4 mobile:mt-16"
            >
              <TextComponent />
            </div>
            <div
              className={`${
                windowWidth >= 1025
                  ? "shared-banner-imageHome"
                  : "shared-banner-imageHomeMobile"
              } mobile:ml-0`}
            >
              <div
                className={`${
                  windowWidth >= 1025
                    ? "bannerExperienceContentStyle"
                    : "bannerContentStyleMobile"
                }`}
              >
                <img
                  src={image}
                  alt="Imagen PopUp"
                  className="hidden tablet:block pb-16 desktop:pb-0 imgBannerExperience"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BannerServicesHunting
