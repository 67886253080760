import React, { Fragment, useState, useContext  } from "react";
import image1 from '../../images/exsisAcademy/ImagePlaceholder.png'
import image2 from '../../images/exsisAcademy/ImagePlaceholder2.png'
import { LoadingContext } from "../context/LoadingContext";
import { Link } from "gatsby"
import i18n from "i18next"
import VideoBanners from "../shared/VideoBanners";
import X from "../../images/home/Xbanners.png";

const BannerExsisAcademy = ({ bannerImageURL, bannerImageContent}) => {

    const [dinamicHeight, setDinamicHeight] = useState(0);
    const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0);
    const { windowWidth } = useContext(LoadingContext);

   

    return (
        <Fragment>
            <div className="container-banner-main">

                <div className="shared-banner-container">
                  {windowWidth >= 1025 ?  
                            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
                            <VideoBanners/>
                            <img className={windowWidth >1700 ? 'xBanners':'xBannersLaptp'} src={X} alt='x'></img>
                            <div ref={el => {
                                if (el) {
                                    const resizeObserver = new ResizeObserver(entries => {
                                        setDinamicHieghtColbanner(entries[0].contentRect.height);
                                    });
                                    resizeObserver.observe(el);
                                }
                            }}
                                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                                {bannerImageContent}
                            </div>
                            <div  className="shared-banner-imageHome mobile:ml-0">
                                <div className="bannerAcademyContentStyle">
                                <img src={bannerImageURL} alt="Imagen PopUp" className="imgBannerExperience" />
                                
                                </div>
                            
                         </div>
                        </div>:
                        <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalMobile heiBannerAcademy">
                       
                        <div ref={el => {
                            if (el) {
                                const resizeObserver = new ResizeObserver(entries => {
                                    setDinamicHieghtColbanner(entries[0].contentRect.height);
                                });
                                resizeObserver.observe(el);
                            }
                        }}
                            className="shared-banner-content-btnAcademy m-4 desktop:mt-4 mobile:mt-16">
                            {bannerImageContent}
                        </div>
                        <div  className="shared-banner-imageHomeMobile mobile:ml-0">
                            <div className="bannerContentStyleMobile">
                            
                            </div>
                        
                     </div>
                    </div>
                  }
                    
                   <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 desktop:ml-4 mobile:ml-2">
                  {windowWidth >= 1025 ?  
                    <div className="flex w-full">
                   <div className="  mt-10 divInfoExsisAcademy ">
                       
                        <div className=''>
                                <img src={image1} alt="...." className="ml-auto"/>
                            </div>
                            
                        <div className=' imgExsisAcademy'>
                                <img src={image2} alt="...." className=""/>
                            </div>
                    </div>
                    <div className="contentWhyUs divInfoExsisAcademy">
                    <div className="eventRightTimeLineMobile">
                    <p className='titleInfoExsisAcademy'>{i18n.t("ExsisAcademy.title")}</p> 
                    </div>
                        
                            <p className="textInfoExsisAcademy">
                            {i18n.t("ExsisAcademy.text")}
                        </p> 
                        <p className="textInfoExsisAcademy2">
                        {i18n.t("ExsisAcademy.text2")}
                          </p>  

                          <div className=''>
                          <div className="desktop:text-left">
                                <Link to={"/contact-us"}>
                                <button className="buttonLetsTalk text-18 font-bold ">{i18n.t("ExsisAcademy.botom")}</button>
                                </Link>
                                </div>
                        </div>    
                        
                    </div> 
                   
                    
                    </div>:
                     <div className="w-full">
                    
                      <div className="ml-2 divInfoExsisAcademyMobile">
                      <div className="eventRightTimeLineMobile">
                      <p className='titleInfoExsisAcademy'>{i18n.t("ExsisAcademy.title")}</p> 
                      </div>
                         <p className="textInfoExsisAcademyMobile">
                         {i18n.t("ExsisAcademy.text")}
                          </p>   
                          <p className="textInfoExsisAcademy2Mobile">
                          {i18n.t("ExsisAcademy.text2")}
                           </p>  
                          
                      </div> 
                      <div className="  mt-10 divInfoExsisAcademyMobile ">
                         
                         <div className=' '>
                                 <img src={image1} alt="...." className=""/>
                             </div>
                             
                         <div className='mt-8 '>
                                 <img src={image2} alt="...." className=""/>
                             </div>
                     </div>

                    <div className="desktop:text-left">
                    <Link to={"/contact-us"}>
                    <button className="buttonLetsTalk text-18 font-bold w-full">{i18n.t("ExsisAcademy.botom")}</button>
                    </Link>
                    </div>   
                      
                      </div>
                    }
                    </div>
                  
                </div>
            </div>
        </Fragment>

    )
}

BannerExsisAcademy.defaultProps = {
    flag: true,
    clase: 'shared-banner-footer banner-text-white mt-8',
    style: {}
}

export default BannerExsisAcademy;
