import React, { Fragment, useState, useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"
import ImageBanner from "../../images/xMediaSuccessStories/BannerSuccessSotires.png"
import i18n from "i18next"
import VideoBanners from "../shared/VideoBanners";
import X from "../../images/home/Xbanners.png";

function BannerXMmediaSuccesStories({ image, TextComponent}) {
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)

  return (
    <>
    {i18n.language === "en" ? (
    <Fragment>
      <div className="">
        <div className="shared-banner-container">
          {windowWidth >= 1025 ? (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
              <VideoBanners/>
              <img className={windowWidth >1700 ? 'xBanners':'xBannersLaptp'} src={X} alt='x'></img>
              <div className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                  <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
                    <span className="textOurExperience2 flex">
                      <p className="subtitleTextTalentX">X&nbsp;</p>
                      <p className="subtitleTextTalent">Media</p>
                    </span>
                    <span className="textTalentATS">
                      <p className="colorTextExperience2">Success</p>
                      <nl />
                      <p className="colorTextExperience2">Stories</p>
                    </span>
                  </h3>
                </div>
              </div>
              <div className="shared-banner-imageHome mobile:ml-0">
                <div className="bannerAcademyContentStyle">
                  <img
                    src={ImageBanner}
                    alt="Imagen PopUp"
                    className="imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex desktop:flex-row bannerPrincipalMobile">
              <div className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                  <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
                    <br></br>
                    <br></br>
                    <br></br>
                    <span className="textOurExperience2 flex">
                      <p className="subtitleTextTalentX">X&nbsp;</p>
                      <p className="subtitleTextTalent">Media</p>
                    </span>
                    <span className="textTalentATS">
                    <p className="colorTextExperience2">Success</p>
                    <nl />
                      <p className="colorTextExperience2">Stories</p>
                      <p className="colorTextExperience2">&nbsp;</p>
                    </span>
                    <br></br>
                    <br></br>
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="">
        <div className="shared-banner-container">
          {windowWidth >= 1025 ? (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
              <VideoBanners/>
              <img className={windowWidth >1700 ? 'xBanners':'xBannersLaptp'} src={X} alt='x'></img>
              <div className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                <div className="flex flex-col txt-our-adn txt-our-adn-movil">
  
                  <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
                  
                    <span className="textOurExperience2 flex">
                      <p className="subtitleTextTalentX">X&nbsp;</p>
                      <p className="subtitleTextTalent">Media</p>
                    </span>
                    <span className="textTalentATS">
                    <p className="colorTextExperience2">Casos de</p>
                    <nl />
                      <p className="colorTextExperience2">Éxito</p>
                    </span>
                  </h3>
                </div>
              </div>
              <div className="shared-banner-imageHome mobile:ml-0">
                <div className="bannerAcademyContentStyle">
                  <img
                    src={ImageBanner}
                    alt="Imagen PopUp"
                    className="imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex desktop:flex-row bannerPrincipalMobile">
              <div className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                  <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
                    <br></br>
                    <br></br>
                    <br></br>
                    <span className="textOurExperience2 flex">
                      <p className="subtitleTextTalentX">X&nbsp;</p>
                      <p className="subtitleTextTalent">Media</p>
                    </span>
                    <span className="textTalentATS">
                    <p className="colorTextExperience2">Casos de</p>
                    <nl />
                      <p className="colorTextExperience2">Éxito</p>
                      <p className="colorTextExperience2">&nbsp;</p>
                    </span>
                    <br></br>
                    <br></br>
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
      )
      
    }
    </>
)
  }

export default BannerXMmediaSuccesStories;
