import React, { useState, useContext, useRef } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../components/context/LoadingContext"
import arrowLeft from "../../images/ourDNA/Group29.png"
import arrowRigth from "../../images/ourDNA/Group30.png"
import juan from "../../images/exsisAcademy/Juan Herrera.jpg"
import joseph from "../../images/exsisAcademy/Joseph Rojas.jpg"
import Samuel from "../../images/exsisAcademy/Samuel Romero.jpg"
import daniel from "../../images/exsisAcademy/Daniel Garay.jpg"
import python from "../../images/exsisAcademy/Technologys/image 320.png"
import java from "../../images/exsisAcademy/Technologys/image 313.png"
import javaScript from "../../images/exsisAcademy/Technologys/image 256.png"
import html from "../../images/exsisAcademy/Technologys/HTML.png"
import css from "../../images/exsisAcademy/Technologys/CSS.png"
import reactNative from "../../images/exsisAcademy/Technologys/image 315.png"
import mongo from "../../images/exsisAcademy/Technologys/image 325.png"
import xElement from "../../images/exsisAcademy/X Element Bootcamp.png"

const TestimonialsAcademy = () => {
  const { windowWidth } = useContext(LoadingContext)
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)

  const team = [
    {
      photo: juan,
      name: "Juan Herrera",
      description:
        "\"Mi experiencia en Exsis ha sido muy gratificante, porque aquí pude comenzar mi carrera como profesional de TI, brindándome no solo la oportunidad de aprender y desarrollar nuevas habilidades, sino también de trabajar y estudiar al mismo tiempo gracias a la flexibilidad en los horarios de trabajo y también a la posibilidad de trabajar de forma remota. Estoy muy feliz de ser parte de este equipo y creo que puedo crecer mucho en esta empresa\".",
      descripEnglish:
        "\"My experience at Exsis has been very rewarding, as it allowed me to start my career as an IT professional. It provided me not only with the opportunity to learn and develop new skills, but also to work and study simultaneously, thanks to the flexibility in work schedules and the possibility of remote work. I am very happy to be part of this team and believe that I can grow a lot within this company\".",
      image: [python, java, javaScript, html],
    },
    {
      photo: joseph,
      name: "Joseph Rojas",
      description:
        "\"Formé parte del primer bootcamp internacional impartido por Exsis. Esta fue una oportunidad que me ayudó a mejorar mis habilidades técnicas en automatización y ciencia de datos, así como mis habilidades interpersonales, ya que tuve la oportunidad de trabajar en un equipo intercultural con personas de Ecuador y México\".",
      descripEnglish:
        "\" I was part of the first international bootcamp offered by Exsis. This was an opportunity that helped me enhance my technical skills in automation and data science, as well as my interpersonal skills, as I had the chance to work in a cross-cultural team with individuals from Ecuador and Mexico\".",
      image: [python, reactNative, mongo, java],
    },
    {
      photo: Samuel,
      name: "Samuel Romero",
      description:
        "\"Mi experiencia en Exsis ha sido buena porque representa un gran paso en mi carrera profesional y me brinda la oportunidad de crecer en mi área laboral\".",
      descripEnglish:
        "\"My experience at Exsis has been positive because it represents a significant step in my professional career and provides me with the opportunity to grow in my field of work\".",
      image: [java, python, html, css],
    },
    {
      photo: daniel,
      name: "Daniel Garay",
      description:
        "\"Hola, mi nombre es Daniel y sobre mi primera experiencia en Exsis, ha sido muy buena, Exsis me ha brindado todas las oportunidades para crecer tanto como profesional como persona\".",
      descripEnglish:
        "\"Hello, my name is Daniel, and regarding my first experience at Exsis, it has been very positive. Exsis has provided me with all the opportunities to grow both as a professional and as an individual\".",
      image: [java, python, css, html],
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    autoPlay: true,
    loop: true,
    adaptiveHeight: true,
    arrows: false,
    autoPlaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 700,
    fade: false,
    dotsClass: "slick-dots blue-dark-slide-dots--small hidden-desktop__dot",
    transition: "all 0.2s",
  }

  const goToSlide = index => {
    setCurrentIndex(index)
    if (carouselRef.current) {
      if (index < currentIndex) {
        carouselRef.current.slickPrev()
      } else if (index > currentIndex) {
        carouselRef.current.slickNext()
      }
    }
  }

  return (
    <div className={windowWidth>1025?" mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card seccionTeamExpert": " mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card seccionTeamExpertMobile"}>
      {windowWidth >= 1025 ? (
        <>
          {/* Título */}
          <div className="titleContentExpert">
            <p className="titleTeamExperts">
              {i18n.t("ExsisAcademy.titleBoot")}
            </p>
            <p className="titleTeamExperts">
              {i18n.t("ExsisAcademy.titleBoot2")}
            </p>
            <div className="flex">
              <p className="titleTeamExperts">
                {i18n.t("ExsisAcademy.titleBoot3")}
              </p>
              <p className="titleTeamExperts colorTextExperience">
                {i18n.t("ExsisAcademy.titleBoot4")}
              </p>
              <p className="titleTeamExperts">
                {i18n.t("ExsisAcademy.titleBoot5")}
              </p>
            </div>
          </div>
          {/* Fin título */}
          <div className="pl-56 pr-24 h-full specific-slider mt-16">
            <Slider {...settings} ref={carouselRef}>
              {team.map((item, i) => {
                return (
                  <div key={i.toString()} className="flex justify-center">
                    <div >
                      <img className="absolute mt-20 -z-50" src={xElement} alt="xElement"/>
                    </div>
                    <div className="contentMediaTeams ml-10 mr-10 z-50">
                      <img
                        src={item.photo}
                        alt="testimonials"
                        className="rounded-xs"
                      />
                    </div>
                    <div className="ml-12">
                      <p className="textTestimonialAcademy">
                        {i18n.language === "en"
                          ? item.descripEnglish
                          : item.description}
                      </p>
                      <div className="flex mt-8">
                        {item.image.map((item, i) => {
                          return (
                            <div className="targetSkillMember">
                              <div className={""}>
                                <img src={item} alt="...." className="m-auto" />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="countTextCountMobile mt-8">
                        <p className="textNameExpert">{item.name}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
            <div className="desktop:flex justify-center margin-arrows-bootcamp mt-10">
              <button onClick={() => goToSlide(currentIndex - 1)}>
                <img src={arrowLeft} alt="left" className="mr-20" />
              </button>
              <button onClick={() => goToSlide(currentIndex + 1)}>
                <img src={arrowRigth} alt="right" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Título */}
          {i18n.language == "en" ? (
            <div className="mt-16">
              <p className="titleTeamExperts eventRightTimeLineMobile">
                {i18n.t("ExsisAcademy.titleBootMobile")}
              </p>
              <p className="titleTeamExperts eventRightTimeLineMobile">
                {i18n.t("ExsisAcademy.titleBootMobile2")}
              </p>
              <p className="titleTeamExperts eventRightTimeLineMobile">
                {i18n.t("ExsisAcademy.titleBootMobile3")}
              </p>
              <p className="titleTeamExperts eventRightTimeLineMobile">
                {i18n.t("ExsisAcademy.titleBootMobile4")}
              </p>
              <div className="flex eventRightTimeLineMobile">
                <p className="titleTeamExperts">
                  {i18n.t("ExsisAcademy.titleBootMobile5")}
                </p>
                <p className="titleTeamExperts colorTextExperience">
                  {i18n.t("ExsisAcademy.titleBootMobile6")}
                </p>
                <p className="titleTeamExperts">
                  {i18n.t("ExsisAcademy.titleBootMobile7")}
                </p>
              </div>
            </div>
          ) : (
            <div className="mt-16">
              <p className="titleTeamExperts eventRightTimeLineMobile">
                {i18n.t("ExsisAcademy.titleBootMobile")}
              </p>
              <p className="titleTeamExperts eventRightTimeLineMobile">
                {i18n.t("ExsisAcademy.titleBootMobile2")}
              </p>
              <p className="titleTeamExperts eventRightTimeLineMobile">
                {i18n.t("ExsisAcademy.titleBootMobile3")}
              </p>
              <div className="flex eventRightTimeLineMobile">
                <p className="titleTeamExperts">
                  {i18n.t("ExsisAcademy.titleBootMobile4")}
                </p>
                <p className="titleTeamExperts colorTextExperience">
                  {i18n.t("ExsisAcademy.titleBootMobile5")}
                </p>
                <p className="titleTeamExperts ">
                  {i18n.t("ExsisAcademy.titleBootMobile6")}
                </p>
              </div>
              <p className="titleTeamExperts eventRightTimeLineMobile">
                {i18n.t("ExsisAcademy.titleBootMobile7")}
              </p>
            </div>
          )}
          {/* Fin título */}
          <div className="specific-slider">
            <Slider {...settings} ref={carouselRef}>
              {team.map((item, i) => {
                return (
                  <div key={i.toString()} className="justify-center">
                    <div className="mt-24 ml-5 mr-5">
                      <img
                        src={item.photo}
                        alt="testimonials"
                        className="rounded-xs"
                      />
                    </div>
                    <div className="mt-10 ml-5 mr-5">
                      <p className="textTestimonialAcademyMobile">
                        {i18n.language === "en"
                          ? item.descripEnglish
                          : item.description}
                      </p>
                    </div>
                    <div className="flex justify-center gap-4 mt-8 mb-8 ml-5 mr-5">
                      {item.image.map((item, i) => {
                        return (
                          <div className="targetSkillMemberBootcamp">
                            <div className={""}>
                              <img src={item} alt="...." className="m-auto  " />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="text-left ml-5 mr-5">
                      <p className="textNameExpert">{item.name}</p>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
          <div className="flex justify-center gap-16 mt-10 mb-10">
            <button onClick={() => goToSlide(currentIndex - 1)}>
              <img src={arrowLeft} alt="leftMobile" className="mt-1" />
            </button>
            <button onClick={() => goToSlide(currentIndex + 1)}>
              <img src={arrowRigth} alt="rightMobile" />
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default TestimonialsAcademy
