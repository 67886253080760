import React, { useEffect } from "react"
import PropTypes from "prop-types"

import TrackingScript from "./tracking/TrackingScript"
import TrackingCrmScript from "./tracking/TrackingCrmScript"
import Footer from "./../components/shared/footer"
import NavBar from "./../components/shared/navBar"
import "./i18n"

import LoadingProvider from "./../components/context/LoadingContext"
import { PostsContextProvider } from "./../components/context/PostsContext"

import "../styles/animate.min.css"
import "../styles/index.css"
import "../styles/styles.css"
import "../styles/App.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import VideoBackground from "./shared/VideoBackground"
import Whatsapp from "./shared/whatsapp/Whatsapp"

const Layout = ({ children }) => {
  useEffect(() => {
    //GTM script
    const script = document.createElement("script")
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PF5FPMXT');
    `
    document.head.appendChild(script)

    //noscript
    const noscript = document.createElement("noscript")
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PF5FPMXT"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `
    document.body.appendChild(noscript)

    // Metricool script
    const metricoolScript = document.createElement("script");
    metricoolScript.innerHTML = `
      function loadScript(a){var b=document.getElementsByTagName("head")[0],
      c=document.createElement("script");c.type="text/javascript",
      c.src="https://tracker.metricool.com/resources/be.js",
      c.onreadystatechange=a,c.onload=a,b.appendChild(c)}
      loadScript(function(){beTracker.t({hash:"29f093d9ecfc25eecce293d82199c256"})});
    `;
    document.head.appendChild(metricoolScript);

    return () => {
      document.head.removeChild(script)
      document.body.removeChild(noscript)
      document.head.removeChild(metricoolScript);
    }
  }, [])
  
  return (
    <LoadingProvider>
      <PostsContextProvider>
        <NavBar />
        {/*  <Header siteTitle={data.site.siteMetadata.title} siteDesc={data.site.siteMetadata.description} /> */}
        <main className="App">{children}</main>
        <VideoBackground/>
        {/*<Sidebar>
        <RecentPosts />
      </Sidebar> */}
        <Whatsapp/>
        <Footer />
        <TrackingScript />
        <TrackingCrmScript />
      </PostsContextProvider>
    </LoadingProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
