import React, { Fragment, useContext } from 'react';
import { LoadingContext } from './../context/LoadingContext';
import i18n from "i18next";

const CardValue = ({ profileIcon, profile, setCloseModal, setProfileSelected }) => {
    const { windowWidth } = useContext(LoadingContext);

    function openModal(e) {
        e.preventDefault();
        setCloseModal(false);
    }

    const cardStyle = {
        backgroundImage: `url(${profile.image})`,
        backgroundSize: 'auto',
        backgroundPosition: 'center',
    };

    if (windowWidth < 600) {
        return (
            <Fragment>
                <div
                    className="profile-card--mobile mobile:mx-2 landscape:mx-4 my-6 p-5"
                >
                    <div style={cardStyle} className="profile-card__sideMobile profile-card__sideNew--front justify-center items-center p-8">
                        <p className="absolute bottom-0 w-full text-center p-2 textTargetValuesNewMobile">
                            {i18n.language === "en" ? profile.texto : profile.textSpanish}
                        </p>
                    </div>
                    <div className="profile-card__sideMobile profile-card__sideNew--back mobile:text-14 mobile:p-4 landscape:p-5">
                        <div className="h-1/4 flex items-center">
                            <p className="titleTargetValue mr-auto ml-auto" style={{ color: profileIcon.color }}>
                                {i18n.language === "en" ? profileIcon.title : profileIcon.textSpanish}
                            </p>
                        </div>
                        <div>
                            <p
                                className={profileIcon.textSpanish === "Responsabilidad"
                                    ? "textTargetOurSpanish my-3 ml-6"
                                    : "textTargetOur my-3 ml-6"}
                            >
                                {i18n.language === "en" ? profileIcon.texto : profileIcon.texto2}
                            </p>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div
                className={windowWidth > 1700
                    ? "profile-card profile-cardValues mobile:mx-2 my-6 p-5 cardResponsiveValues"
                    : "profile-card profile-cardValues mobile:mx-2 landscape:mx-4 my-6 p-5"}
            >
                <div   style={cardStyle} className="profile-card__sideNew profile-card__sideNew--front items-center p-8">
                    <div className="absolute bottom-0 w-full text-center p-2">
                        <p className="mobile:text-18 landscape:text-24 textTargetValuesNew ">
                            {i18n.language === "en" ? profile.texto : profile.textSpanish}
                        </p>
                    </div>
                </div>
                <div className="profile-card__sideNew profile-card__sideNew--back mobile:px-8 mobile:py-3 mobile:text-12 landscape:text-14">
                    <div className="items-center mt-4">
                        <p className="titleTargetValue mr-auto ml-auto" style={{ color: profileIcon.color }}>
                            {i18n.language === "en" ? profileIcon.title : profileIcon.textSpanish}
                        </p>
                    </div>
                    <div>
                        <p
                            className={profileIcon.textSpanish === "Responsabilidad"
                                ? "textTargetOurSpanish my-3"
                                : "textTargetOur my-3"}
                        >
                            {i18n.language === "en" ? profileIcon.texto : profileIcon.texto2}
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CardValue;
