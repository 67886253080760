import React, { useContext } from "react"
import { LoadingContext } from "../../components/context/LoadingContext"

const TargetExperts = ({ imagesArr, titleArr }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <>
      {windowWidth >= 1025 ? (
        <>
          <p className="titleStandarAgile titleSectionStack">{titleArr}</p>
          <div className="flex targetExperts mt-12 ">
            {imagesArr.slice(0, 4).map((item, i) => {
              return (
                <div
                  className={
                    windowWidth > 1700
                      ? "contentTargetExpertsResponsiveBackground mr-8"
                      : "contentTargetExpertsBackground mr-8"
                  }
                >
                  {windowWidth > 1700 ? (
                    <img
                      src={item.image}
                      alt="...."
                      className={
                        item.texto == "3"
                          ? "imgCardExpertIonic2"
                          :  "imgCardExpert"
                      }
                    />
                  ):(
                    <img
                      src={item.image}
                      alt="...."
                      className={
                        item.texto == "3"
                          ? "imgCardExpertIonic2"
                          :  "imgCardExpert"
                      }
                    />
                  )}
                  
                </div>
              )
            })}
          </div>
          <div className="flex targetExperts mt-12 ">
            {imagesArr.slice(4, 8).map((item, i) => {
              return (
                <div
                  className={
                    windowWidth > 1700
                      ? "contentTargetExpertsResponsive2Background mr-8"
                      : "contentTargetExpertsBackground mr-8"
                  }
                >
                  {windowWidth > 1700 ? (
                    <img
                      src={item.image}
                      alt="...."
                      className={
                        item.texto == "3"
                          ? "imgCardExpertIonic2"
                          :  "imgCardExpert"
                      }
                    />
                  ):(
                    <div className="">
                    <img
                      src={item.image}
                      alt="...."
                      className={
                        item.texto == "3"
                          ? "imgCardExpertIonic2"
                          :  "imgCardExpert"
                      }
                    />
                  </div>
                  )}
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <>
          <p className="titleStandarAgile mt-8 ml-4">{titleArr}</p>
          <div className=" targetExpertsMobile mt-12 ">
            {imagesArr.map((item, i) => {
              return (
                <div className="mb-8">
                  <div className="contentTargetExpertsMobileBackground ">
                      <img
                        src={item.image}
                        alt="...."
                        className={
                          item.texto == "3"
                            ? "imgCardExpertIonic2"
                            :  "imgCardExpert"
                        }
                      />
                    </div>
                  </div>
              )
            })}
          </div>
        </>
      )}
    </>
  )
}

export default TargetExperts
