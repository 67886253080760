import React, { useState, useEffect, useRef } from "react"
import i18n from "i18next"
import columnImageRight from "../images/servicesExpansion/ImagenBanner.png"
import Layout from "../components/layoutServices"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"
import BannerExpansionServices from "../components/expansionServices.js/BannerExpansionServices"
import StaffAugmentation from "../components/expansionServices.js/StaffAugmentation"
import SoftwareFactory from "../components/expansionServices.js/SoftwareFactory"
import ExcepcionalHuntingExpansion from "../components/expansionServices.js/ExcepcionalHuntingExpansion"
import ExsisBlockchainExpasion from "../components/expansionServices.js/ExsisBlockchainExpasion"
import Licency from "../components/expansionServices.js/Licency"
import InfoLicency from "../components/expansionServices.js/InfoLicency"
import Consultancy from "../components/expansionServices.js/Consultancy"
import ConsultoryInfo from "../components/expansionServices.js/ConsultoryInfo"
import ManageServices from "../components/expansionServices.js/ManageServices"
import ManageServicesInfo from "../components/expansionServices.js/ManageServicesInfo"
import Training from "../components/expansionServices.js/Training"
import TrainingInfo from "../components/expansionServices.js/TrainingInfo"
import Blockchain from "../components/expansionServices.js/Blockchain"
import BlockchainInfo from "../components/expansionServices.js/BlockchainInfo"
import DataAnalitycs from "../components/expansionServices.js/DataAnalitycs"
import DataAnalitycsInfo from "../components/expansionServices.js/DataAnalitycsInfo"

const ServiceExpansion = () => {
  const [titleHorizons, setTitleHorizons] = useState([])

  useEffect(() => {
    if (i18n.language === "en") {
      setTitleHorizons([
        <div className="">
          <h3 className="">
            <span className="textOurExperience2 flex">
              <p className="textExpanding">E</p>
              <p className="textExpandingBlue">x</p>
              <p className="textExpanding">panding Horizons:</p>
            </span>
            <span className="textExpanding">
              Leveraging Technology for a Better World
            </span>
          </h3>
        </div>,
      ])
    } else {
      setTitleHorizons([
        <div className="">
          <h3 className="">
            <span className="textExpanding">
              Creemos en el poder de la tecnología para conseguir un mundo
              mejor.
            </span>
          </h3>
        </div>,
      ])
    }
  }, [i18n.language])

  return (
    <Layout>
      <SEO
        title={"Services Expansion | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />

      <BannerExpansionServices
        bannerImageURL={columnImageRight}
        titleHorizons={titleHorizons}
      ></BannerExpansionServices>

      <StaffAugmentation />
      <SoftwareFactory />

      <ExcepcionalHuntingExpansion />
      <ExsisBlockchainExpasion />

      <Licency />
      <InfoLicency/>

      <Consultancy />
      <ConsultoryInfo />

      <ManageServices />
      <ManageServicesInfo />

      <Training />
      <TrainingInfo />

      <Blockchain />
      <BlockchainInfo/>

      <DataAnalitycs />
      <DataAnalitycsInfo />

    </Layout>
  )
}

export default withNamespaces()(ServiceExpansion)
