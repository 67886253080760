import React, { useState, useContext, useEffect, useRef, Fragment } from "react"
import arrowLeft from "./../../images/ourDNA/OurNewDNA/Grupo439.png"
import arrowRigth from "./../../images/ourDNA/OurNewDNA/Grupo440.png"
import { LoadingContext } from "../context/LoadingContext";
import i18n from "i18next";
import VideoBanners from "../shared/VideoBanners";
import X from "../../images/home/Xbanners.png";
import Slider from "react-slick"
import imageArrow from '../../images/joinOurTeam/Grupo 127.png'
import imageArrow2 from '../../images/joinOurTeam/Grupo 128.png'
import imageVideo from '../../images/joinOurTeam/portada-video-30años1.png'
import overlayImage from '../../images/joinOurTeam/play_16281999.png'
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import videoUrl from "../../images/joinOurTeam/Video 30 años.mp4"
import ReactPlayer from "react-player/lazy"
import fondo1 from "../../images/ourDNA/Carruselexperiencia/Grupo225.png";
import fondo12 from "../../images/ourDNA/Carruselexperiencia/Grupo322.png";


import image1 from '../../images/ourDNA/Carruselexperiencia/1.png'
import image2 from '../../images/ourDNA/Carruselexperiencia/2.png'
import image3 from '../../images/ourDNA/Carruselexperiencia/3.png'
import image4 from '../../images/ourDNA/Carruselexperiencia/4.png'
import image5 from '../../images/ourDNA/Carruselexperiencia/5.png'
import image6 from '../../images/ourDNA/Carruselexperiencia/6.png'
import image7 from '../../images/ourDNA/Carruselexperiencia/7.png'
import image8 from '../../images/ourDNA/Carruselexperiencia/8.png'
import image9 from '../../images/ourDNA/Carruselexperiencia/9.png'
import image10 from '../../images/ourDNA/Carruselexperiencia/10.png'
import image11 from '../../images/ourDNA/Carruselexperiencia/11.png'
import image12 from '../../images/ourDNA/Carruselexperiencia/12.png'
import image13 from '../../images/ourDNA/Carruselexperiencia/13.png'
import image14 from '../../images/ourDNA/Carruselexperiencia/14.png'
import image15 from '../../images/ourDNA/Carruselexperiencia/15.png'
import image16 from '../../images/ourDNA/Carruselexperiencia/16.png'
import image17 from '../../images/ourDNA/Carruselexperiencia/17.png'
import image18 from '../../images/ourDNA/Carruselexperiencia/18.png'
import image19 from '../../images/ourDNA/Carruselexperiencia/19.png'
import image20 from '../../images/ourDNA/Carruselexperiencia/20.png'


const BannerOurExperience = ({ bannerImageURL, bannerImageContent }) => {

    const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0);
    const { windowWidth } = useContext(LoadingContext);
    const [currentIndex, setCurrentIndex] = useState(0)
    const carouselRef = useRef(null)
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const ourCulture = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12,
        image13, image14, image15, image16, image17, image18, image19, image20];

    const settings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "linear",
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    speed: 2000,
                    autoplaySpeed: 4000,
                },
            },
        ],
    };
    const goToSlide = (index) => {
        if (index < 0 || index >= ourCulture.length) return;
        setCurrentIndex(index);
        if (carouselRef.current) {
            if (index < currentIndex) {
                carouselRef.current.slickPrev();
            } else if (index > currentIndex) {
                carouselRef.current.slickNext();
            }
        }
    };


    return (
        <Fragment>
            <div className="container-banner-main">

                <div className="shared-banner-container">
                    {windowWidth >= 1025 ?
                        <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
                            <VideoBanners />
                            <img className={windowWidth > 1700 ? 'xBanners' : 'xBannersLaptp'} src={X} alt='x'></img>
                            <div ref={el => {
                                if (el) {
                                    const resizeObserver = new ResizeObserver(entries => {
                                        setDinamicHieghtColbanner(entries[0].contentRect.height);
                                    });
                                    resizeObserver.observe(el);
                                }
                            }}
                                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                                {bannerImageContent}
                            </div>
                            <div className="shared-banner-imageHome mobile:ml-0">
                                <div className="bannerExperienceContentStyle">
                                    <img src={bannerImageURL} alt="Imagen PopUp" className="imgBannerExperience" />

                                </div>

                            </div>
                        </div> :
                        <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalMobile">
                            <VideoBanners />
                            <div ref={el => {
                                if (el) {
                                    const resizeObserver = new ResizeObserver(entries => {
                                        setDinamicHieghtColbanner(entries[0].contentRect.height);
                                    });
                                    resizeObserver.observe(el);
                                }
                            }}
                                className="shared-banner-content-btnAcademy m-4 desktop:mt-4 mobile:mt-4 pb-48">
                                {bannerImageContent}
                            </div>
                            <div className="shared-banner-imageHomeMobile mobile:ml-0">
                                <div className="bannerContentStyleMobile">

                                </div>

                            </div>
                        </div>
                    }

                    {windowWidth >= 1025 ?
                        <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 ml-4">
                            <div className="mt-8 w-full">
                                <div className="titleOurHistoric">
                                    <p className='titleOurServices titleOurHistoric'>{i18n.t('home.history.subtitle')}</p>
                                </div>

                            </div>
                            <div className="w-full contentApply flex ml-8 mt-10 ">
                                <div className="mr-6">
                                    <img src={imageArrow2} alt="...." className="" />
                                </div>

                                <div className="image-container " onClick={() => handleClickOpen()}>
                                    <img src={imageVideo} alt="Imagen dinámica" className="main-image styleBorder" />
                                </div>


                                <div className="mr-6">
                                    <img src={imageArrow} alt="...." className="" />
                                </div>
                            </div>


                        </div> :
                        <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 ml-2">
                            <div className="ml-4 mr-8">
                                <div className="eventRightTimeLineMobile">
                                    <p className='titleOurServices'>{i18n.t('home.history.subtitle')}</p>
                                </div>

                            </div>
                            <div className="mt-10">
                                <div className="expand-icon mt-10">

                                    <div className="image-containerMobile " onClick={() => handleClickOpen()}>
                                        <img src={imageVideo} alt="Imagen dinámica" className="main-image styleBorder" />

                                    </div>
                                </div>
                            </div>

                        </div>

                    }

                    {windowWidth >= 1025 ?
                        <div className="desktop:flex desktop:px-6 mt-20 ml-4 ">
                            <div className="contentWhyUs withExperience">
                                <div className="eventRightTimeLineMobile">
                                    <p className='textAboutUs'>{i18n.t('OurExperienceWhyUs.subtitle')}</p>
                                    <p className='titleOurServices'>{i18n.t('OurExperienceWhyUs.title')}</p>
                                </div>

                                <p className="text_our_culture mt-4">
                                    {i18n.t('OurExperienceWhyUs.text1')}<span className="text-blue">{i18n.t('OurExperienceWhyUs.text2')}</span>{i18n.t('OurExperienceWhyUs.text3')}<span className="text-blue">{i18n.t('OurExperienceWhyUs.text4')}</span> {i18n.t('OurExperienceWhyUs.text5')}
                                </p>

                                <p className="text_our_culture mt-4">
                                    {i18n.t('OurExperienceWhyUs.text6')}
                                </p>

                            </div>
                            <div className="withExperience ml-8 mt-10 mr-16">

                                <div>
                                    <img src={fondo1} alt="...." className="bg-fixed-container " />
                                </div>

                                <Slider {...settings} ref={carouselRef}>
                                    {ourCulture.map((item, i) => {
                                        return (
                                            <div key={i.toString()}>
                                                <div className="pr-5">
                                                    <img src={item} alt="...." className="carruselExperience" />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>

                                <div className="desktop:flex desktop:pr-12 ml-auto ">
                                    <div className="desktop:flex ml-auto arrowsCarrusel2 mt-4">
                                        <button onClick={() => goToSlide(currentIndex - 1)}>
                                            <img src={arrowLeft} alt="left" className="mt-1 arrowsCarrusel" />
                                        </button>
                                        <button onClick={() => goToSlide(currentIndex + 1)}>
                                            <img src={arrowRigth} alt="right" className="arrowsCarrusel" />
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <img src={fondo12} alt="...." className="bg-fixed-container2" />
                                </div>

                            </div>


                        </div> :
                        <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 ml-4">
                            <div className="ml-4 mr-8">
                                <div className="eventRightTimeLineMobile">
                                    <p className='textAboutUs'>{i18n.t('OurExperienceWhyUs.subtitle')}</p>
                                    <p className='titleOurServices'>{i18n.t('OurExperienceWhyUs.title')}</p>
                                </div>

                                <p className="text_our_experienceMobile">
                                    {i18n.t('OurExperienceWhyUs.text1')}<span className="text-blue">{i18n.t('OurExperienceWhyUs.text2')}</span>{i18n.t('OurExperienceWhyUs.text3')}<span className="text-blue">{i18n.t('OurExperienceWhyUs.text4')}</span> {i18n.t('OurExperienceWhyUs.text5')}
                                </p>

                                <p className="text_our_experienceMobile">
                                    {i18n.t('OurExperienceWhyUs.text6')}
                                </p>

                            </div>
                            <div className="mt-10">
                                <Slider {...settings} ref={carouselRef}>
                                    {ourCulture.map((item, i) => {
                                        return (
                                            <div key={i.toString()}>
                                                <div className="pr-5">
                                                    <img src={item} alt="...." className="carruselExperience" />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                                <div className="desktop:flex desktop:pr-12 ml-auto ">
                                    <div className="desktop:flex ml-auto gap-20 mobile:mt-8">
                                        <button onClick={() => goToSlide(currentIndex - 1)} className="styleMobileArrow">
                                            <img src={arrowLeft} alt="left" className="mt-1 " />
                                        </button>
                                        <button onClick={() => goToSlide(currentIndex + 1)}>
                                            <img src={arrowRigth} alt="right" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    }



                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={false}
                className="VideoHistoric"
            >
                <DialogContent>
                    <div>
                        <ReactPlayer
                            width={windowWidth >= 1025 ? "66rem" : "auto"}
                            height="100%"
                            className="VideoHistoric"
                            playing={true}
                            controls={true}
                            url={videoUrl}
                        />
                    </div>
                </DialogContent>
            </Dialog>

        </Fragment>

    )
}

BannerOurExperience.defaultProps = {
    flag: true,
    clase: 'shared-banner-footer banner-text-white mt-8',
    style: {}
}

export default BannerOurExperience;
