import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/servicesExpansion/view-people-addicted-their-smartphone-looking-scrolling-through-screens.png"
import image2 from "../../images/servicesExpansion/two-it-professional-workers-male-female-d-1720076235-3.png"
import image3 from "../../images/servicesExpansion/view-people-addicted-their-smartphone-looking-scrolling-through-screens (1).png"
import { Link } from "gatsby"
import xElement from "../../images/servicesExpansion/Grupo344.png"

const TrainingInfo = ({ }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div
      className={windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card "}
    >

      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 paddingLicency">
          <div className="flex">
            <div className="tech-section">


              <div className="mt-8">

                <p className="textInfoFabrica mt-4">
                  {i18n.t("textServices.textNew67")}
                </p>


                <p className="textFabrica mt-12">
                  {i18n.t("textServices.textNew68")}
                </p>

                <p className="textFabrica mt-4">
                  {i18n.t("textServices.textNew69")}
                </p>
              </div>

              <div className="contentViewMoreProducts mt-16">
                <Link to="/contact-us">
                  <button
                    className="mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                    style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                  >
                    <p className="textBottomAgendProducts">
                    {i18n.t("textServices.textNew12")}
                    </p>
                  </button>
                </Link>
              </div>

            </div>
            <div className="mt-10 tech-section justify-center w-1/2 mx-auto">

              <div className="flex w-full justify-start">
                <img className="w-1/3 desktop:w-fit styleXelement xElementTrining" src={xElement} alt="xs" />
              </div>

              <div className="flex">
                <img src={image1} alt="..." className="imageTraining" />

                <div className="w-1/2">
                  <div className="inline-block">
                    <img src={image2} alt="..." className="w-full " />
                  </div>
                  <div className="inline-block">
                    <img src={image3} alt="..." className="w-full " />
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-start">
                <img className="w-1/3 desktop:w-fit styleXelement" src={xElement} alt="xs" />
              </div>
            </div>


          </div>

        </div>
      ) : (
        <div className="flip-card-inner2">

          <div className="">

            <p className="textInfoFabrica mt-4">
              {i18n.t("textServices.textNew67")}
            </p>


            <p className="textFabrica mt-12">
              {i18n.t("textServices.textNew68")}
            </p>

            <p className="textFabrica mt-4">
              {i18n.t("textServices.textNew69")}
            </p>
          </div>




          <div className="mt-10 ">
            <img src={image1} alt="...." className="ml-auto mr-auto" />
            <img src={image2} alt="...." className="ml-auto mr-auto" />
            <img src={image3} alt="...." className="ml-auto mr-auto" />
          </div>



          <div className="my-4">
            <Link to="/contact-us">
              <button
                className="w-full mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
              >
                <p className="textBottomAgendProducts">
                {i18n.t("textServices.textNew12")}
                </p>
              </button>
            </Link>
          </div>

        </div>
      )}
    </div>
  )
}

export default TrainingInfo
