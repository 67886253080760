import React from 'react';
import bgVideo from '../../images/servicesExpansion/BackgroundServiciosDigitales.mp4';

const VideoBackground = ({ children }) => {
  return (
    <div className="video-background-container">
      <video autoPlay loop muted playsInline className="background-videoServices">
        <source src={bgVideo} type="video/mp4" />
        ...
      </video>
    </div>
  );
};

export default VideoBackground;
