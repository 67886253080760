import React, { useContext, useState, useEffect } from "react"
import arrowLeft from "./../../images/home/arrowLeft.png"
import arrowRigth from "./../../images/home/arrowRigth.png"
import TargetWork from "./TargetWork"
import { LoadingContext } from "../../components/context/LoadingContext"
import i18n from "i18next"
import { Link } from "gatsby"
import { getOffers } from "./../../utils/api"
import { getOffersById } from "./../../utils/api.js"
import imgV1 from "../../images/targetVacancies/imgVacancie1.png"; //customer
import imgV2 from "../../images/targetVacancies/imgVacancie2.png"; //analyst - qa
import imgV3 from "../../images/targetVacancies/imgVacancie3.png"; //developer
import imgV4 from "../../images/targetVacancies/imgVacancie4.png"; //project manager - n.a
import imgV5 from "../../images/targetVacancies/imgVacancie5.png"; //architect - engineer

const JoinOurTeamHome = () => {
  const { windowWidth } = useContext(LoadingContext)

  const [initialValue, setInitialValue] = useState(0)
  const [finalValue, setFinalValue] = useState(3)
  const [jobs, setJobs] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const formatDate = (dateString, language) => {
    const months = {
      'es': ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      'en': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    };
  
    const parts = dateString.split(' ');
    const day = parts[0];
    const monthText = parts[1].toLowerCase();
    const year = parts[2];
    const monthIndex = months['es'].indexOf(monthText);
  
    if (monthIndex !== -1) {
      const formattedMonth = months[language][monthIndex].substring(0, 3).charAt(0).toUpperCase() + months[language][monthIndex].substring(0, 3).slice(1);
      return `${day} ${formattedMonth} ${year}`;
    } else {
      // Si no se encuentra el mes, devolvemos la fecha original
      return dateString;
    }
  };

  const getVacancyImage = (jobName) => {
    const lowercaseJobName = jobName.toLowerCase(); // Convertir el nombre de la vacante a minúsculas para hacer coincidencias sin importar mayúsculas o minúsculas
  
    if (lowercaseJobName.includes("customer")) {
      return imgV1;
    } else if (lowercaseJobName.includes("analyst") || lowercaseJobName.includes("analista") || lowercaseJobName.includes("qa")) {
      return imgV2;
    } else if (lowercaseJobName.includes("developer") || lowercaseJobName.includes("dev") || lowercaseJobName.includes("desarrollador")) {
      return imgV3;
    } else if (lowercaseJobName.includes("project") || lowercaseJobName.includes("manager")) {
      return imgV4;
    } else if (
      lowercaseJobName.includes("architect") ||
      lowercaseJobName.includes("engineer") ||
      lowercaseJobName.includes("arquitecto") ||
      lowercaseJobName.includes("ingeniero")
    ) {
      return imgV5;
    } else {
      return imgV4;
    }
  };

  useEffect(() => {
    async function getJobs() {
      try {
        const response = await getOffers(); // Obtiene los datos de trabajo
        const jobsWithInitialTime = response.data.obj.map((job) => ({
          ...job,
          time: i18n.t("pagejoinourteam.vacancies.loadingData"), // Inicializa con el mensaje de carga
          ubication: `${job.jobLocation?.cityName || ""}, ${job.jobLocation?.countryName || ""}`,
          description: (job?.description.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '')).split(/\s+/).slice(0, 28).join(' ') + ' \u2026',
          timeCreated: formatDate(job.creationDate, i18n.language) || i18n.t("pagejoinourteam.targetVacancies.timeCreatedEmpty"),
          timeLast: formatDate(job.editedDate, i18n.language) || i18n.t("pagejoinourteam.targetVacancies.timeCreatedEmpty"),
          publication: job.publicationDate,
          vacancyImage: getVacancyImage(job.name),
        }));
        setJobs(jobsWithInitialTime);
        setIsLoading(false);
        // Lanzar la segunda petición después de haber renderizado las tarjetas
        await Promise.all(jobsWithInitialTime.map(async (job) => {
          const jobInfo = await getJobInfoById(job.jobId);
          const correctedTime = jobInfo?.jobLocationModality?.name ?? i18n.t("pagejoinourteam.vacancies.nodata");
          setJobs(prevJobs => prevJobs.map(prevJob => prevJob.jobId === job.jobId ? { ...prevJob, time: correctedTime } : prevJob));
        }));
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    getJobs();
  }, [ i18n.language]);
  
  async function getJobInfoById(jobId) {
    try {
      const response = await getOffersById(jobId);
      const jobInfo = response.data.obj;
      return {
        ...jobInfo,
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  }


  function handleBefore() {
    if (initialValue == 0) {
      setInitialValue(jobs.length - 3)
      setFinalValue(jobs.length)
    } else {
      setInitialValue(initialValue - 3)
      setFinalValue(finalValue - 3)
    }
  }

  function handleAfter() {
    if (finalValue == jobs.length) {
      setInitialValue(0)
      setFinalValue(3)
    } else {
      setInitialValue(initialValue + 3)
      setFinalValue(finalValue + 3)
    }
  }

  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card pyWork">
      <div className="flip-card-inner2 desktop:ml-16 mt-24">
        <div className="">
          <p className="subTitleOurServices">
            {i18n.t("home.vacanciesHome.title")}
          </p>
          {windowWidth >= 1025 ? (
            <div className="flex">
              {i18n.language == "en" ? (
                <>
                  <p className="titleOurServices">E</p>
                  <p className="titleOurServices colorEx">x</p>
                  <p className="titleOurServices">
                    ceed your potential with us
                  </p>
                </>
              ) : (
                <>
                  <p className="titleOurServices">Trabaja con nosotros</p>{" "}
                </>
              )}
            </div>
          ) : (
            <div className="">
              {i18n.language == "en" ? (
                <>
                  <div className="flex">
                    <p className="titleOurServices">E</p>
                    <p className="titleOurServices colorEx">x</p>
                    <p className="titleOurServices">ceed your </p>
                  </div>
                  <p className="titleOurServices">potential with us</p>
                </>
              ) : (
                <>
                  <p className="titleOurServices">Trabaja con nosotros</p>{" "}
                </>
              )}
            </div>
          )}
        </div>
        <div className="desktop:flex desktop:mr-20 bigdesktop:mr-40 ">
          <div>
            {windowWidth >= 1025 ? (
              <p className="textCoreSolutions desktop:text-20 mobile:text-16 desktop:text-left  mb-8 mobile:px-6">
                {i18n.t("home.vacanciesHome.text")}
              </p>
            ) : (
              <p className="textCoreSolutionsMobile desktop:text-20 mobile:text-16 text-left  mb-8 ">
                {i18n.t("home.vacanciesHome.text")}
              </p>
            )}
          </div>
          {windowWidth >= 1025 ? (
            <div className="flex ml-auto mt-4 mb-4">
              <button className="mobile:ml-auto" onClick={() => handleBefore()}>
                <img
                  src={arrowLeft}
                  alt="Imagen PopUp"
                  className="mt-1 desktop:mr-8 mobile:mr-24"
                />
              </button>
              <button className="mobile:mr-auto" onClick={() => handleAfter()}>
                <img src={arrowRigth} alt="Imagen PopUp" className="" />
              </button>
            </div>
          ) : null}
        </div>

        {isLoading ? (
          <div className="">
            <p className="resultsSearch mt-10 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 ">
              {i18n.t("pagejoinourteam.vacancies.loading")}
            </p>
          </div>
        ) : (
          <TargetWork
            workArray={jobs}
            finalValue={finalValue}
            initialValue={initialValue}
          />
        )}

        {windowWidth < 1025 ? (
          <div className="flex ml-auto mobile:mt-12">
            <button className="mobile:ml-auto" onClick={() => handleBefore()}>
              <img
                src={arrowLeft}
                alt="Imagen PopUp"
                className="mt-1 desktop:mr-8 mobile:mr-24"
              />
            </button>
            <button className="mobile:mr-auto" onClick={() => handleAfter()}>
              <img src={arrowRigth} alt="Imagen PopUp" className="" />
            </button>
          </div>
        ) : null}

        <Link to={"/exceptional-careers"}>
          <button
            className={
              windowWidth >= 1025
                ? "buttonViewMoreWork ml-auto mr-auto mt-12"
                : "contentButtomViewAllMobile ml-auto mr-auto mt-12"
            }
            style={{ alignSelf: "center" }}
          >
            <p
              className={
                windowWidth >= 1025
                  ? "textButtomViewMoreWork"
                  : "colorTextAllServicesMobile"
              }
            >
              {i18n.t("home.vacanciesHome.bottom")}
            </p>
          </button>
        </Link>
      </div>
    </div>
  )
}

export default JoinOurTeamHome
