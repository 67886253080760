import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/servicesExpansion/person-looking-blockchain-concept-screen-cryptocurrency-business-fintech.png"
import image2 from "../../images/servicesExpansion/customer-relationship-management-concept.png"
import image3 from "../../images/servicesExpansion/representation-user-experience-interface-design.png"
import image4 from "../../images/servicesExpansion/Grupo334.png"
import { Link } from "gatsby"
import xElement from "../../images/servicesExpansion/Grupo332.png"


const BlockchainInfo = ({ }) => {
    const { windowWidth } = useContext(LoadingContext)

    return (
        <div
            className={windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card "}
        >

            {windowWidth >= 1025 ? (
                <div className="flip-card-inner2 paddingLicency">
                    <div className="flex">
                        <div className="tech-section">


                            <div className="mt-8">
                                <p className="textFabrica mt-4">
                                   Somos especialistas en talento TI, formando equipos ágiles y mucho más relacionado con esta era digital.
                                </p>

                                <p className="textFabrica mt-4">
                                {i18n.t("textServices.textNew70")}
                                </p>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 1" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew71")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 2" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew72")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 3" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew73")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew74")}
                                    </p>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew75")}
                                    </p>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew76")}
                                    </p>
                                </div>


                            </div>

                            <div className="contentViewMoreProducts mt-8">
                                <Link to="/services-blockchain">
                                    <button
                                        className="mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                                        style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                                    >
                                        <p className="textBottomAgendProducts">
                                        {i18n.t("textServices.textNew27")}
                                        </p>
                                    </button>
                                </Link>
                            </div>

                        </div>
                        <div className="mt-10 tech-section justify-center w-1/2 mx-auto">

                            <div className="flex">

                                <div className="w-1/2">
                                    <div className="inline-block">
                                        <img src={image2} alt="..." className="w-full imageBlockchain" />
                                    </div>
                                    <div className="inline-block">
                                        <img src={image3} alt="..." className="w-full mt-8 imageBlockchain" />
                                    </div>
                                </div>

                                <img src={image1} alt="..." className="w-1/2" />


                            </div>

                            <div className="flex w-full justify-start">
                                    <img className="w-1/3 desktop:w-fit styleXelement xElementTrining imageBlockchain2" src={xElement} alt="xs" />
                                </div>

                                <div className="flex w-full justify-start">
                                <img className="w-1/3 desktop:w-fit styleXelement imageBlockchain2 imageBlockchain3" src={xElement} alt="xs" />
                            </div>
                        </div>


                    </div>

                </div>
            ) : (
                <div className="flip-card-inner2 ">
                       <div className="">
                                <p className="textFabrica mt-4">
                                   Somos especialistas en talento TI, formando equipos ágiles y mucho más relacionado con esta era digital.
                                </p>

                                <p className="textFabrica mt-4">
                                {i18n.t("textServices.textNew70")}
                                </p>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 1" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew71")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 2" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew72")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 3" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew73")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew74")}
                                    </p>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew75")}
                                    </p>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew76")}
                                    </p>
                                </div>


                            </div>


                            <div className="mt-10 ">
                            <img src={image2} alt="...." className="ml-auto mr-auto" />
                            <img src={image3} alt="...." className="ml-auto mr-auto" />
                            <img src={image1} alt="...." className="ml-auto mr-auto" />
                        </div>

                     <div className="my-4">
                                <Link to="/services-blockchain">
                                  <button
                                    className="w-full mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                                    style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                                  >
                                    <p className="textBottomAgendProducts">
                                    {i18n.t("textServices.textNew27")}
                                    </p>
                                  </button>
                                </Link>
                              </div>


                </div>
            )}
        </div>
    )
}

export default BlockchainInfo
