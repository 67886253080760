import React, { Fragment, useEffect } from "react"
import Form from "react-bootstrap/Form"
import { PopupboxContainer } from "react-popupbox"
import i18n from "i18next"
import Button from "react-bootstrap/Button"
import {
  getInterest,
  getProfilesAugmentation,
  getProfilesHunting,
} from "./../../utils/api.js"
import { withNamespaces } from "react-i18next"
import PrivacyPolicy from "../shared/privacyPolicy/PrivacyPolicy.js"
import { LoadingContext } from "../../components/context/LoadingContext"
import Swal from "sweetalert2"
import { sendContacUs } from "./../../utils/api.js"
import { Link } from "gatsby"

const ContactForm = () => {

  useEffect(() => {
    // Asegúrate de que el contenedor existe
    const container = document.getElementById("escala-form-container");
    if (!container) {
      console.error("Contenedor para el formulario no encontrado.");
      return;
    }
  
    const script = document.createElement("script");
    script.id = "escala-form-facc9f8d-c948-4799-a5a0-161bed41188c";
    script.setAttribute("data-version", "0.1.0");
    script.async = true;
  
    script.innerHTML = `
      window["escala-form-facc9f8d-c948-4799-a5a0-161bed41188c-ran041463104970285025dom"] = {
        e: "page",
        f: "facc9f8d-c948-4799-a5a0-161bed41188c",
        l: "es",
        r: "ran041463104970285025dom",
      };
      function escInitFormImpl(opts) {
        (function () {
          var iframeElem = document.createElement("iframe");
          iframeElem.setAttribute("src", "https://escalapages.com/plugins/forms/embed.html?form=" + opts.f + "&lang=" + opts.l);
          iframeElem.setAttribute("scrolling", "no");
          iframeElem.style.width = "100%";
          iframeElem.style.height = "500px";
          iframeElem.setAttribute("escala-element-form", "escala-form-" + opts.r);
          const container = document.getElementById("escala-form-container");
          if (container) container.appendChild(iframeElem);
        })();
      }
      escInitFormImpl(window["escala-form-facc9f8d-c948-4799-a5a0-161bed41188c-ran041463104970285025dom"]);
    `;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        className="relative overflow-hidden tablet:px-8  form-contact mb-32"
        style={{ marginBottom: "-2px" }}
      >
        <div className="flex elementDesktop mobile:flex-wrap desktop:pl-12 desktop:pr-12 mobile:pl0 mobile:pr0">
          <div className="mt-16  w-full ">
            <div className="content-wrapper">
              <div className="min-h-full formContactShadow desktop:p-20 mobile:p-8">
         
                  <div className="mobile:ml-4 mobile:mr-4 mobile:mt-12 mobile:mb-16 boxTitleForm2 desktop:mt-2">
                    <p className="titleForm desktop:text-44 landscape:text-44 mobile:text-24">
                      {i18n.t("home.titleFormTalk")}
                    </p>
                  </div>
                  <div id="escala-form-container"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withNamespaces()(ContactForm)
