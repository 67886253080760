import React, { Fragment, useState, useContext } from "react"
import { Link } from "gatsby"
import { LoadingContext } from "../context/LoadingContext"
import i18n from 'i18next';
import VideoBanners from "../shared/VideoBannerAcademy";
import imageFondo from "../../images/joinOurTeam/Grupo 128.png";

const BannerExsisAcademy = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className={ windowWidth >= 1025 ? "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerAcademyMobile"}>
      <VideoBanners />
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 flex mt-8 mb-8">
          <div className="ml-4">
          <img src={imageFondo} alt="...." />
          </div>
          <div className="ml-32 mt-auto mb-auto">
            <p className="titleBannerAcademy">{i18n.t('OurExperienceWhyUs.titleAcademy')}</p>
            <p className="conteBannerAcademy my-4">
            {i18n.t('OurExperienceWhyUs.textAcademy')}
            </p>
            <Link to={"/exsis-academy"}>
              <button
                className="mobile:mt-4 conButtomMoreAcademy boxTransform "
                style={{ alignSelf: "center" }}
              >
                <p className="textButtomGetTouch colorTextExperience2">{i18n.t('OurExperienceWhyUs.botomAcademy')}</p>
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="flip-card-inner2   mt-24 mb-24">
          <div>
            <p className="titleBannerAcademy">{i18n.t('OurExperienceWhyUs.titleAcademy')}</p>
            <p className="textBannerAcademyMobile my-4 ">
            {i18n.t('OurExperienceWhyUs.textAcademy')}
            </p>
            <Link to={"/exsis-academy"}>
              <button
                className="mobile:mt-4 conButtomMoreAcademy boxTransform w-full"
                style={{ alignSelf: "center" }}
              >
                <p className="textButtomGetTouch  colorTextExperience2">{i18n.t('OurExperienceWhyUs.botomAcademy')}</p>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default BannerExsisAcademy
