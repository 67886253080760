import React, { useState, useEffect, useRef } from "react";
import i18n from "i18next";

import CustomerTestimonials from "./../components/ourDNA/CustomerTestimonials";
import OurTeam from "./../components/ourDNA/OurTeam";

import columnImageRight from '../images/ourDNA/ImageOurExperienceBanner.png';

// Layout
import Layout from "../components/layout";
import SEO from "../components/seo";
import { withNamespaces } from "react-i18next";


import BannerOurExperience from "../components/ourDNA/BannerOurExperience";
import OurValues from "../components/ourDNA/OurValues";
import BannerExsisAcademy from "../components/ourDNA/BannerExsisAcademy";
import Certification from "../components/ourDNA/Certification";



const OurExperience = () => {
  const [descriptionTitleBanner, setDescriptionTitleBanner] = useState([]);
  const [youtubeVideoId, setYoutubeVideoId] = useState('');
  
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView();
  

  const attributesMainTitle = { 
      id: "OurExperience", 
      title: i18n.t('pageourdna.ourteam'), 
      styles: { bulletColor: "dark-blue", 
      textColor: "light-blue", fontSize: "44", 
      fontSizeMobile: "36", alignMobile: "left", 
      alignDesktop: "left", marginL: "20" 
    } 
  }
  useEffect(() => {
    if (i18n.language === 'en') {
      
      setYoutubeVideoId('GcDt4A3Xbn4');
      setDescriptionTitleBanner([
        <div className="flex flex-col txt-our-adn txt-our-adn-movil">
      <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-center mb-4 mobile:mx-2 desktop:mx-24">
      <span className="textOurExperience">
          Our
          </span>
          <span className="textOurExperience2 flex">
           <p className="colorTextExperience2">E</p> 
           <p className="colorTextExperience">x</p>
           <p className="colorTextExperience2">perience</p>
          </span>
        </h3>
      </div>
      ])

    } else {
      
      setYoutubeVideoId('EGpJEq_JRPA');
          setDescriptionTitleBanner([
            <div className="flex flex-col txt-our-adn txt-our-adn-movil">
      <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-center mb-4 mobile:mx-2 desktop:mx-24">
      <span className="textOurExperience">
           Nuestra 
          </span>
          <span className="textOurExperience2 flex">
           <p className="colorTextExperience2">E</p> 
           <p className="colorTextExperience">x</p>
           <p className="colorTextExperience2">periencia</p>
          </span>
            </h3>
          </div>
      ])
    }
  }, [i18n.language]);

  const attributesWhoare = {
		id: "OurTeamDNA",
		title: i18n.t('pageourdna.whoweare.title'),
		styles: {
			bulletColor: "light-blue",
			textColor: "light-blue",
			fontSizeMobile: "36",
			fontSize: "44",
			alignMobile: "left",
			alignDesktop: "left",
			marginL: "20",
		}
	}

  
  
  return (
    <Layout>
        <SEO title={"Our Experience | Exsis Digital Angels"} description={"Nearshore Agile Teams | Specialists in Tech Talent"} />
      
       <BannerOurExperience
          bannerImageURL={columnImageRight}
          bannerImageContent={descriptionTitleBanner}>
        </BannerOurExperience>

        <OurValues
        title="Core Solutions"
        div="whyusdiv"
         />

      <OurTeam  />
      <BannerExsisAcademy />
      <CustomerTestimonials />
           
    </Layout>
  );
};

export default  withNamespaces()(OurExperience);
