import React, { useEffect, useState, useRef, useContext } from "react";
import { LoadingContext } from "../context/LoadingContext";
import i18n from "i18next"

const NumericInfoServices = () => {
    const { windowWidth } = useContext(LoadingContext)

  const data = [
    { value: 204, label: i18n.t("numericInfo.comprojects"), increment: 10 },
    { value: 50, label: i18n.t("numericInfo.actcustomers"), increment: 2 },
    { value: 600, label: i18n.t("numericInfo.ITprof"), increment: 20 },
  ];

  const [counts, setCounts] = useState(data.map(() => 0));
  const [hasAnimated, setHasAnimated] = useState(false);
  const containerRef = useRef(null);

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return rect.top < window.innerHeight && rect.bottom > 0;
  };

  const startAnimation = () => {
    const durations = 2000;
    const startTime = performance.now();

    const animate = () => {
      const elapsed = performance.now() - startTime;

      const updatedCounts = data.map((item, index) => {
        const progress = Math.min(elapsed / durations, 1);
        return Math.ceil(progress * item.value);
      });

      setCounts(updatedCounts);

      if (elapsed < durations) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && isInViewport(containerRef.current)) {
        if (!hasAnimated) {
          setHasAnimated(true);
          startAnimation();
        }
      } else if (hasAnimated) {
        setHasAnimated(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasAnimated, data]);

  return (
    <div ref={containerRef} className="numeric-info-containerServicesMobile">
      {data.map((item, index) => (
        <div key={index} className={windowWidth > 500 ? "numeric-info-item":" numeric-info-itemMobile"}>
          <span className="numeric-info-value font-avenir-black textColorBlue">
            {item.label !== "Países" && item.label !== "Años de experiencia" ? "+" : ""}
            {counts[index]}
          </span>
          <span className="bannerServicesText">{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default NumericInfoServices;
