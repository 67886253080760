import React, { Fragment, useState, useContext } from "react"
import { Link } from "gatsby"
import { LoadingContext } from "../context/LoadingContext"
import i18n from 'i18next';
import imageFondo from "../../images/products/Grupo493.png";
import imageFondo1 from "../../images/products/mockup3.3.png";

const Exstaff = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className={ windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full flip-card "}>
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 flex sharedBannerExstaff ">
          <div className="ml-4">
          <img src={imageFondo} alt="...." />
          </div>
          <div className="ml-12 mt-auto mb-auto flex">
          <p className="titleBannerTalent alingCenter">E<span className="styled-letter">x</span>staff (ATS)</p>
          <img src={imageFondo1} alt="...." className="contentImageEX"/>
          </div>
        </div>
      ) : (
        <div className="flip-card-inner2 sharedBannerExstaff  mt-24 mb-24">
          <div>
          <p className="titleBannerTalent">E<span className="styled-letter">x</span>staff (ATS)</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Exstaff
