import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/servicesExpansion/attractive-asian-sme.png"
import image2 from "../../images/servicesExpansion/businessman-man-holds-hand-hourglasses-icon-time-expires-reminder-action-business-concept-elements-design.png"
import image3 from "../../images/servicesExpansion/standard-quality-control-certification-assurance-guarantee-quality-assurance-guarantee-product.png"
import image4 from "../../images/servicesExpansion/Grupo334.png"
import { Link } from "gatsby"
import xElement from "../../images/servicesExpansion/Grupo344.png"
import image5 from "../../images/servicesExpansion/Amazon_Web_Services_Logo.svg.png"
import image6 from "../../images/servicesExpansion/Grupo 347.png"

const InfoLicency = ({ }) => {
    const { windowWidth } = useContext(LoadingContext)

    return (
        <div
            className={windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card "}
        >

            {windowWidth >= 1025 ? (
                <div className="flip-card-inner2 paddingLicency">
                    <div className="flex">
                        <div className="tech-section">


                            <div className="mt-8">
                                <p className="textFabrica mt-4">
                                {i18n.t("textServices.textNew28")}
                                </p>

                                <p className="textFabrica mt-4">
                                {i18n.t("textServices.textNew29")}
                                </p>

                                <p className="textFabrica mt-4">
                                {i18n.t("textServices.textNew30")}
                                </p>


                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 1" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew31")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 2" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew32")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 3" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew33")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew34")}
                                    </p>
                                </div>



                            </div>

                        <div className="grid">
                            <div className="contentViewMoreProducts mt-8 flex">
                                <img src={image6} alt="Índice 1" className="logosAws" />
                                <img src={image5} alt="Índice 1" className="ml-24 logosAws" />

                            </div>


                            <div className="contentViewMoreProducts mt-8">
                                <Link to="/contact-us">
                                    <button
                                        className="mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy "
                                        style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                                    >
                                        <p className="textBottomAgendProducts">
                                        {i18n.t("textServices.textNew12")}
                                        </p>
                                    </button>
                                </Link>
                            </div>
                            </div>

                        </div>
                        <div className="mt-6 tech-section justify-center w-1/2 mx-auto">

                            <div className="flex">

                                <div className="w-1/2">
                                    <div className="inline-block">
                                        <img src={image2} alt="..." className="w-full imagenLicency " />
                                    </div>
                                    <div className="inline-block">
                                        <img src={image3} alt="..." className="w-full imagenLicency2" />
                                    </div>
                                </div>

                                <img src={image1} alt="..." className="w-1/2" />


                            </div>

                            <div className="flex w-full justify-end pr-32">
                                <img className="w-1/3 desktop:w-fit styleXelement XelemntLicency" src={xElement} alt="xs" />
                            </div>
                        </div>


                    </div>

                </div>
            ) : (
                <div className="flip-card-inner2">
                    <div className="">
                                <p className="textFabrica mt-4">
                                {i18n.t("textServices.textNew28")}
                                </p>

                                <p className="textFabrica mt-4">
                                {i18n.t("textServices.textNew29")}
                                </p>

                                <p className="textFabrica mt-4">
                                {i18n.t("textServices.textNew30")}
                                </p>


                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 1" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew31")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 2" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew32")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 3" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew33")}
                                    </p>
                                </div>

                                <div className="mt-6 flex items-center">
                                    <img src={image4} alt="Índice 4" className="separator-image" />
                                    <p className="textFabrica ml-4">
                                    {i18n.t("textServices.textNew34")}
                                    </p>
                                </div>



                            </div>

                    <div className="mt-8 ">
                        <img src={image6} alt="Índice 1" className="" />
                        <img src={image5} alt="Índice 1" className="mt-8 ml-auto mr-auto" />

                    </div>

                    <div className="mt-10">

                        <div className="">

                        <img src={image2} alt="...." className="ml-auto mr-auto" />
                        <img src={image3} alt="...." className="ml-auto mr-auto" />
                        <img src={image1} alt="...." className="ml-auto mr-auto" />
                        </div>

                    </div>

                   <div className="my-4">
                               <Link to="/contact-us">
                                 <button
                                   className="w-full mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                                   style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                                 >
                                   <p className="textBottomAgendProducts">
                                   {i18n.t("textServices.textNew12")}
                                   </p>
                                 </button>
                               </Link>
                             </div>


                </div>
            )}
        </div>
    )
}

export default InfoLicency
