import React, { useState, useEffect, useRef, useContext } from "react";
import i18n from "i18next"; 
import b1ES from "../../images/home/BannerHome/ES/Banner1.jpg";
import b2ES from "../../images/home/BannerHome/ES/Banner2.jpg";
import b3ES from "../../images/home/BannerHome/ES/Banner3.jpg";
import b4ES from "../../images/home/BannerHome/ES/Banner4.jpg";
import b1EN from "../../images/home/BannerHome/EN/Banner1.jpg";
import b2EN from "../../images/home/BannerHome/EN/Banner2.jpg";
import b3EN from "../../images/home/BannerHome/EN/Banner3.jpg";
import b4EN from "../../images/home/BannerHome/EN/Banner4.jpg";
import { LoadingContext } from "../context/LoadingContext";

const MainBannerHome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);
  const { windowWidth } = useContext(LoadingContext);
  const trackRef = useRef(null);

  useEffect(() => {
    const banners = i18n.language === "es" 
      ? [b1ES, b2ES, b3ES, b4ES] 
      : [b1EN, b2EN, b3EN, b4EN];
    setImages(banners);
  }, [i18n.language]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 8000);
    return () => clearInterval(interval);
  }, [images]);

  const handleDotClick = (index) => setCurrentIndex(index);

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartX(e.type === "mousedown" ? e.clientX : e.touches[0].clientX);
  };

  const handleDragMove = (e) => {
    if (isDragging) {
      const currentX = e.type === "mousemove" ? e.clientX : e.touches[0].clientX;
      setDragOffset(currentX - startX);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    if (dragOffset > 50) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    } else if (dragOffset < -50) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
    setDragOffset(0);
  };

  return (
    <div
      className="main-banner-container"
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      <div
        ref={trackRef}
        className="carousel-track"
        style={{
          transform: `translateX(calc(-${currentIndex * 100}% + ${dragOffset}px))`,
          transition: isDragging ? "none" : "transform 0.5s ease",
        }}
      >
        {images.map((image, index) => (
          <div key={index} className="banner-slide">
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>

      <div className="dots-container">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default MainBannerHome;
  