import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/servicesExpansion/professional-software-developer-coding-progra.png"
import image2 from "../../images/servicesExpansion/blue-digital.png"
import image3 from "../../images/servicesExpansion/futuristic-hand-working-laptop.png"
import { Link } from "gatsby"

const SoftwareFactory = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div
      className={ windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card "}
    >
      
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 p-8">
          <div className="flex">
          <div className="tech-section">
        
            <p className="textIA my-8">
            {i18n.t("textServices.textNew10")}
            </p>
            <p className="textIA my-8">
            {i18n.t("textServices.textNew11")}
            </p>
          </div>
          <div className="mt-6 tech-section flex justify-center w-1/2 mx-auto">
          <img src={image1} alt="..." className="imagenIA1" />
          <img src={image2} alt="..." className="imagenIA2" />
          <img src={image3} alt="..." className="imagenIA2 " />
        </div>
          </div>
          <div className="">
              <Link to="/contact-us">
                <button
                  className="mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                  style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                >
                  <p className="textBottomAgendProducts">
                  {i18n.t("textServices.textNew12")}
                  </p>
                </button>
              </Link>
            </div>

        </div>
      ) : (
        <div className="flip-card-inner2 mb-24">

          <div className="mt-8">
            <p className="textIAMobile mt-4">
            {i18n.t("textServices.textNew10")}
            </p>

            <p className="textIAMobile mt-4">
            {i18n.t("textServices.textNew11")}
            </p>

          </div>

          <div className="mt-10 ">
            <img src={image1} alt="...." className="ml-auto mr-auto" />
            <img src={image2} alt="...." className="ml-auto mr-auto" />
            <img src={image3} alt="...." className="ml-auto mr-auto" />
          </div>
          
          

          <div className="my-4">
            <Link to="/contact-us">
              <button
                className="w-full mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
              >
                <p className="textBottomAgendProducts">
                {i18n.t("textServices.textNew12")}
                </p>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default SoftwareFactory
