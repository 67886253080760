import React from "react"
import columnImageRight from "../images/products/BannerProducts.png"
import Layout from "../components/layoutTalent"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"
import BannerExclusiveProducts from "../components/Products/BannerExclusiveProducts"
import AgileTeam from "../components/Products/AgileTeam"
import Conection from "../components/Products/Conection"
import AgileTransformation from "../components/Products/AgileTransformation"
import Technologys from "../components/Products/Technologys"
import Exstaff from "../components/Products/Exstaff"
import ExstaffInfo from "../components/Products/ExstaffInfo"
import AgileTeamsNew from "../components/Products/AgileTeamsNew"
import AgileTeamsInfo from "../components/Products/AgileTeamsInfo"
import AgileTransformationNew from "../components/Products/AgileTransformationNew"
import AgileTransformationInfo from "../components/Products/AgileTransformationInfo"

const ExclusiveProducts = () => {
  return (
    <Layout>
      <SEO
        title={"ExclusiveProducts | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />
      <BannerExclusiveProducts
        bannerImageURL={columnImageRight}
      ></BannerExclusiveProducts>
      
      <AgileTeam />
      <Conection />
      
      <AgileTransformation />
      <Technologys />

      <Exstaff />
      <ExstaffInfo />

      <AgileTeamsNew />
      <AgileTeamsInfo />

      <AgileTransformationNew />
      <AgileTransformationInfo />

    </Layout>
  )
}
export default withNamespaces()(ExclusiveProducts)