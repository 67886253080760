import React, { Fragment, useState, useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"
import AxaColpatria from "../../images/xMediaSuccessStories/Stories/AXAColHome.png"
import Dafiti from "../../images/xMediaSuccessStories/Stories/dafitiHome.png"
import Chubb from "../../images/xMediaSuccessStories/Stories/chubbHome.png"
import i18n from "i18next"
import { Link } from "@reach/router"

function StoriesTargetsHome() {
  const { windowWidth } = useContext(LoadingContext)

  const stories = [
    {
      img: AxaColpatria,
      title: i18n.t("pageMediaSuccessStories.axaColpatria.title"),
      subtitle: i18n.t("pageMediaSuccessStories.textTargetsHome"),
      page: "axaColpatria"
    },
    {
        img: Chubb,
        title: i18n.t("pageMediaSuccessStories.chubb.title"),
        subtitle: i18n.t("pageMediaSuccessStories.textTargetsHome"),
        page: "chubb"
    },
    {
      img: Dafiti,
      title: i18n.t("pageMediaSuccessStories.dafiti.title"),
      subtitle: i18n.t("pageMediaSuccessStories.textTargetsHome"),
      page: "dafiti"
    }
  ]

  return (
    <>
    <div className={`flex flex-wrap desktop:px-20 mb-8 ${windowWidth < 1025 ? "flex-col" : ""}`}>
      {stories.map((story, index) => (
        <div
          key={index}
          className={`w-${windowWidth < 1025 ? "full" : "1/3"} p-6`}
        >
          <div
            className="relative overflow-hidden"
          >
            <Link to={`/open-success-stories/${story.page}`}>    
            <img
              src={story.img}
              alt={story.title}
              className="w-full h-full object-cover"
            />
            <div
                className="absolute bottom-0 left-0 right-0 p-6 shadowProjectImg2"
              >
                <div className="absolute bottom-0 p-4 w-full from-black to-transparent text-left">
                <p className="titleTargetSuccess">{story.title}</p>
                <p className={ windowWidth < 1025 ? "subtitleTargetSuccess marginBSuccessMobile": "subtitleTargetSuccess marginBSuccess"}>{story.subtitle}</p>
                </div>
            </div>
            </Link>
          </div>
          
        </div>
      ))}
    </div>
    <Link to={"/x-media-success-stories"}>
          <button
            className={
              windowWidth >= 1025
                ? "buttonViewMoreWork ml-auto mr-auto"
                : "contentButtomViewAllMobile ml-auto mr-auto mt-12"
            }
            style={{ alignSelf: "center" }}
          >
            <p
              className={
                windowWidth >= 1025
                  ? "textButtomViewMoreWork"
                  : "colorTextAllServicesMobile"
              }
            >
              {i18n.t("OurExperienceWhyUs.botomAcademy")}
            </p>
          </button>
        </Link>
    </>
    )
    

}

export default StoriesTargetsHome