import React, { Fragment, useState, useContext } from "react"
import i18n from "i18next"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { LoadingContext } from "./context/LoadingContext"
import videoUrl from "../images/home/VideoExsis2023.mp4"
import ReactPlayer from "react-player/lazy"
import { Link } from "gatsby"
import X from "../images/home/Xbanners.png"

const SharedBanner = ({
  bannerImageURL,
  scrollForm,
}) => {
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      {windowWidth > 1025 ? (
        <div className="shared-banner-containerMainHome">        
          <img className={windowWidth >1700 ? 'xBannerHome':'xBannerHomelaptp'} src={X} alt='x'></img>
        <div className="banner-layout horizontal-layout">

          <div className="banner-text-container">
              <p className={windowWidth>1700?"titleBannerHome font-avenir-black":"titleBannerHomeResponsive font-avenir-black"}>{i18n.t("bannerVideo.title")}</p>
              <p className={windowWidth >1700 ? "textBannerHome font-avenir-medium": "textBannerHomeResponsive font-avenir-medium"}>
              {i18n.t("bannerVideo.text1P1")}
              <span className="text-blue-100 font-avenir-black">{i18n.t("bannerVideo.text2P1")}</span>
              {i18n.t("bannerVideo.text3P1")}
              <span className="text-blue-100 font-avenir-black">{i18n.t("bannerVideo.text4P1")}</span>
              </p>
              {/* <p className={windowWidth>1700?"textBannerHome font-avenir-medium mb-10": "textBannerHomeResponsive font-avenir-medium mb-10"}>{i18n.t("bannerVideo.text1P2")}</p> */}
              <Link to="/contact-us">
              <button className="bg-blue-100 w-full desktop:w-fit text-white text-16 font-sofiaPro-bold rounded-lg px-12 py-4 buttonViewMoreWork mb-8 ml-6">
                <p className="textButtomViewMoreWork">{i18n.t("pageServiceHunting.whyUsHunting.button")}</p>
              </button>
            </Link>
          </div>

          <div className="banner-image" onClick={handleClickOpen}>
            <img src={bannerImageURL} alt="Imagen PopUp" className="imgBannerExperience"/>
          </div>

        </div>
      </div>
      ):(
        <div className="shared-banner-containerMainHomeMobile">        
          <div className="banner-layout vertical-layout">
            <div className="banner-image mt-2" onClick={handleClickOpen}>
              <img src={bannerImageURL} alt="Imagen PopUp" className="imgBannerExperience"/>
            </div>
            <div className="banner-text-container">
                <p className="textBannerHomeResponsive2 font-avenir-medium">
                {i18n.t("bannerVideo.text1P1")}
                <span className="text-blue-100 font-avenir-black">{i18n.t("bannerVideo.text2P1")}</span>
                {i18n.t("bannerVideo.text3P1")}
                <span className="text-blue-100 font-avenir-black">{i18n.t("bannerVideo.text4P1")}</span>
                </p>
            </div>
            
            <div className="banner-text-container mt-2">
                <Link to="/contact-us">
                <button className="bg-blue-100 w-full desktop:w-fit text-white text-16 font-sofiaPro-bold rounded-lg px-12 py-4 buttonViewMoreWork buttonViewMoreWorkMobile mb-8">
                  <p className="textButtomViewMoreWork">{i18n.t("pageServiceHunting.whyUsHunting.button")}</p>
                </button>
              </Link>
            </div>
          </div>
        </div>
      )
      }

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={false}
        >
          <DialogContent>
            <div>
              <ReactPlayer
                width="66rem"
                height="100%"
                className="VideoDiv"
                playing={true}
                controls={true}
                url={videoUrl}
              />
            </div>
          </DialogContent>
        </Dialog>
    </Fragment>
  )
}

SharedBanner.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
}

export default SharedBanner
