import React, { Fragment, useState, useContext } from "react"
import { Link } from "gatsby"
import { LoadingContext } from "../context/LoadingContext"
import i18n from 'i18next';
import imageFondo from "../../images/products/Grupo493.png";

const AgileTransformationNew = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return ( 
    <div className={ windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full flip-card "}>
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 flex sharedBannerTransformation ">
          <div className="ml-4">
          <img src={imageFondo} alt="...." />
          </div>
          <div className="ml-12 mt-auto mb-auto">
            <p className="titleBannerTalent">{i18n.t("textServices.textNew89")}</p>
          </div>
        </div>
      ) : (
        <div className="flip-card-inner2 sharedBannerTransformation  mt-24 mb-24">
          <div>
            <p className="titleBannerTalent">{i18n.t("textServices.textNew89")}</p>
            
          </div>
        </div>
      )}
    </div>
  )
}

export default AgileTransformationNew
