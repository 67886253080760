import React from "react"
import i18n from "i18next"
import { Link } from "gatsby"

function BannerBtnRequestQ() {
  return (
    <>
      <div className="hidden tablet:block mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerRequestQuote">
        <div className="flip-card-inner2 contentBannerAcademy  mt-24 mb-24">
          <div className="">
          <p className="titleBannerAcademy">{i18n.t("pageSoftwareFactory.bannerRequest.title")}</p>
            <p className="textBannerAcademy my-4">{i18n.t("pageSoftwareFactory.bannerRequest.text")}<br/>{i18n.t("pageSoftwareFactory.bannerRequest.text2")}</p>
            {/* missing route */}
            <div className="w-fit">
              <Link to={"/contact-us"}>
                <button
                  className="mobile:mt-4 contentButtomMoreAcademy bottomNew textButtomGetTouch font-sofiaPro-bold"
                  style={{ alignSelf: "center" }}
                >
                  <p className="textButtomGetTouchNav">{i18n.t("pageSoftwareFactory.bannerRequest.btn")}</p>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="tablet:hidden mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card BgBannerRequestQuoteMobile pt-3 pb-3">
        <div className="flip-card-inner2  mt-24 mb-24">
          <div className="">
          <p className="titleBannerAcademy">{i18n.t("pageSoftwareFactory.bannerRequest.title")}</p>
            <p className="textBannerAcademyMobile my-4">{i18n.t("pageSoftwareFactory.bannerRequest.text")}<br/>{i18n.t("pageSoftwareFactory.bannerRequest.text2")}</p>
            {/* missing route */}
            <div className="my-10">
              <Link to={"/contact-us"}>
                <button
                  className="mobile:mt-4 w-full text-16 contentButtomRequestQ textButtomRequestMobile font-sofiaPro-bold"
                  style={{ alignSelf: "center" }}
                >
                  <p className="textButtomGetTouchNav">{i18n.t("pageSoftwareFactory.bannerRequest.btn")}</p>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BannerBtnRequestQ
