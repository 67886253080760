import React, { useContext, useState } from "react";
import { Link } from "@reach/router";
import { LoadingContext } from "../context/LoadingContext";
import ITTalent from "../../images/home/ITTalent.png";
import digitalServ from "../../images/home/digitalServices.png";
import bgVideo from "../../images/home/MainServicesVideo.mp4";
import i18n from "i18next";

function MainServices() {
  const { windowWidth } = useContext(LoadingContext);
  const [hoveredCard, setHoveredCard] = useState(null);

  const cards = [
    {
      img: ITTalent,
      title: i18n.t("home.mainServices.ITquest"),
      description: i18n.t("home.mainServices.ITdesc"),
      link: "/exclusive-products",
    },
    {
      img: digitalServ,
      title: i18n.t("home.mainServices.DSquest"),
      description: i18n.t("home.mainServices.DSdesc"),
      link: "/service-expansion",
    },
  ];

  return (
    <div className="contMainServicesHome flex flex-col items-center px-6 desktop:px-20 mb-20 relative">
      <div className="video-background-containerMainServices">
        <video autoPlay loop muted playsInline className="banners-video">
          <source src={bgVideo} type="video/mp4" />
        </video>
      </div>

      <h1 className={windowWidth>1025?"titleMainServices":"titleMainServicesMobile"}>{i18n.t("home.mainServices.title")}</h1>
      <p className={windowWidth>800 ? "subtitleMainServices": "subtitleMainServicesMobile"}>{i18n.t("home.mainServices.subtitle")}</p>
      <div className={`flex flex-wrap ${windowWidth < 1025 ? "flex-col" : ""}`}>
        {cards.map((card, index) => (
          <div
            key={index}
            className={`w-${windowWidth < 1025 ? "full" : "1/2"} p-6 pl-16 pr-16`}
            onMouseEnter={() => setHoveredCard(index)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <div
              className={`relative overflow-hidden cardMServices ${
                hoveredCard === index ? "hovered" : ""
              }`}
            >
              <Link to={card.link}>
                <div className="imageContainer">
                  <img
                    src={card.img}
                    alt={card.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                {windowWidth > 1025 ? (
                  <div
                  className={`absolute bottom-0 left-0 right-0 shadowProjectImg3 ${
                    hoveredCard === index ? "expanded" : ""
                  }`}
                >
                  <div className="absolute bottom-0 p-8 w-full text-center">
                    <p className="titleMainServices">{card.title}</p>
                    {hoveredCard === index && (
                      <div className="contTextTargetMainServices">
                        <p className={windowWidth>1700?"subtitleTargetMainServices pb-20":"subtitleTargetMainServices2"}>{card.description}</p>
                        <p className="subtitleTargetMainServices pt-16 pb-4">
                          {i18n.t("home.mainServices.viewMore")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                ):(
                <div
                  className={`absolute bottom-0 left-0 right-0 shadowProjectImg4 ${
                    hoveredCard === index ? "expanded" : ""
                  }`}
                >
                  <div className="absolute bottom-0 p-8 w-full text-center">
                    <p className={windowWidth>1025?"titleMainServices":"titleMainServicesMobile"}>{card.title}</p>
                    {hoveredCard === index && (
                      <div className="contTextTargetMainServices">
                        <p className={windowWidth>1700?"subtitleTargetMainServices pb-20":"subtitleTargetMainServices2"}>{card.description}</p>
                        <p className="subtitleTargetMainServices pt-16 pb-4">
                          {i18n.t("home.mainServices.viewMore")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                )}
                

              </Link>
            </div>
          </div>
        ))}
      </div>
      <Link to="/contact-us">
        <button className="buttonViewMoreWork ml-auto mr-auto mt-12">
          <p className="textButtomViewMoreWork">{i18n.t("home.mainServices.btnView")}</p>
        </button>
      </Link>
    </div>
  );
}

export default MainServices;
