import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/servicesExpansion/automation-business-industrial-process.png"
import image2 from "../../images/servicesExpansion/shot.png"
import image3 from "../../images/servicesExpansion/engineer.png"
import image4 from "../../images/servicesExpansion/Grupo334.png"
import { Link } from "gatsby"
import xElement from "../../images/servicesExpansion/Grupo344.png"


const ManageServicesInfo = ({ }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div
      className={windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card "}
    >

      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 p-16">
          <div className="flex">
            <div className="tech-section">


              <div className="mt-8">
                <p className="textFabrica mt-4">
                  {i18n.t("textServices.textNew52")} <span className="highlighted-text">{i18n.t("textServices.textNew53")}</span>{i18n.t("textServices.textNew54")}
                  <span className="highlighted-text">{i18n.t("textServices.textNew55")} </span>{i18n.t("textServices.textNew56")}
                </p>

                <p className="textFabrica mt-4">
                  {i18n.t("textServices.textNew57")}{" "}
                  <span className="highlighted-text">{i18n.t("textServices.textNew58")}</span> {i18n.t("textServices.textNew59")}
                </p>


                <p className="textInfoFabrica mt-4">
                  {i18n.t("textServices.subServices")}
                </p>


                <div className="mt-4 flex items-center">
                  <img src={image4} alt="Índice 1" className="separator-image" />
                  <p className="textFabrica ml-4">
                    {i18n.t("textServices.textNew60")}
                  </p>
                </div>

                <div className="mt-4 flex items-center">
                  <img src={image4} alt="Índice 2" className="separator-image" />
                  <p className="textFabrica ml-4">
                    {i18n.t("textServices.textNew61")}
                  </p>
                </div>

                <div className="mt-4 flex items-center">
                  <img src={image4} alt="Índice 3" className="separator-image" />
                  <p className="textFabrica ml-4">
                    {i18n.t("textServices.textNew62")}
                  </p>
                </div>

                <div className="mt-4 flex items-center">
                  <img src={image4} alt="Índice 4" className="separator-image" />
                  <p className="textFabrica ml-4">
                    {i18n.t("textServices.textNew63")}
                  </p>
                </div>

                <div className="mt-4 flex items-center">
                  <img src={image4} alt="Índice 4" className="separator-image" />
                  <p className="textFabrica ml-4">
                    {i18n.t("textServices.textNew64")}
                  </p>
                </div>

                <div className="mt-4 flex items-center">
                  <img src={image4} alt="Índice 4" className="separator-image" />
                  <p className="textFabrica ml-4">
                    {i18n.t("textServices.textNew65")}
                  </p>
                </div>

                <div className="mt-4 flex items-center">
                  <img src={image4} alt="Índice 4" className="separator-image" />
                  <p className="textFabrica ml-4">
                    {i18n.t("textServices.textNew66")}
                  </p>
                </div>



              </div>

              <div className="contentViewMoreProducts mt-8">
                <Link to="/contact-us">
                  <button
                    className="mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                    style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                  >
                    <p className="textBottomAgendProducts">
                      {i18n.t("textServices.textNew12")}
                    </p>
                  </button>
                </Link>
              </div>

            </div>
            <div className="mt-8 tech-section justify-center w-1/2 mx-auto">

              <div className="">

                <img src={image1} alt="..." className="" />

                <div className="flex">
                  <div className="inline-block">
                    <img src={image2} alt="..." className="w-full " />
                  </div>
                  <div className="inline-block">
                    <img src={image3} alt="..." className="w-full " />
                  </div>
                </div>

              </div>

              <div className="flex w-full justify-end pr-32">
                <img className="w-1/3 desktop:w-fit styleXelement XelemntLicency" src={xElement} alt="xs" />
              </div>
            </div>


          </div>

        </div>
      ) : (
        <div className="flip-card-inner2">

          <div className="">
            <p className="textFabrica mt-4">
              {i18n.t("textServices.textNew52")} <span className="highlighted-text">{i18n.t("textServices.textNew53")}</span>{i18n.t("textServices.textNew54")}
              <span className="highlighted-text">{i18n.t("textServices.textNew55")} </span>{i18n.t("textServices.textNew56")}
            </p>

            <p className="textFabrica mt-4">
              {i18n.t("textServices.textNew57")}{" "}
              <span className="highlighted-text">{i18n.t("textServices.textNew58")}</span> {i18n.t("textServices.textNew59")}
            </p>


            <p className="textInfoFabrica mt-4">
              {i18n.t("textServices.subServices")}
            </p>


            <div className="mt-4 flex items-center">
              <img src={image4} alt="Índice 1" className="separator-image" />
              <p className="textFabrica ml-4">
                {i18n.t("textServices.textNew60")}
              </p>
            </div>

            <div className="mt-4 flex items-center">
              <img src={image4} alt="Índice 2" className="separator-image" />
              <p className="textFabrica ml-4">
                {i18n.t("textServices.textNew61")}
              </p>
            </div>

            <div className="mt-4 flex items-center">
              <img src={image4} alt="Índice 3" className="separator-image" />
              <p className="textFabrica ml-4">
                {i18n.t("textServices.textNew62")}
              </p>
            </div>

            <div className="mt-4 flex items-center">
              <img src={image4} alt="Índice 4" className="separator-image" />
              <p className="textFabrica ml-4">
                {i18n.t("textServices.textNew63")}
              </p>
            </div>

            <div className="mt-4 flex items-center">
              <img src={image4} alt="Índice 4" className="separator-image" />
              <p className="textFabrica ml-4">
                {i18n.t("textServices.textNew64")}
              </p>
            </div>

            <div className="mt-4 flex items-center">
              <img src={image4} alt="Índice 4" className="separator-image" />
              <p className="textFabrica ml-4">
                {i18n.t("textServices.textNew65")}
              </p>
            </div>

            <div className="mt-4 flex items-center">
              <img src={image4} alt="Índice 4" className="separator-image" />
              <p className="textFabrica ml-4">
                {i18n.t("textServices.textNew66")}
              </p>
            </div>



          </div>

          <div className="mt-10 ">
            <img src={image1} alt="...." className="ml-auto mr-auto" />
            <img src={image2} alt="...." className="ml-auto mr-auto" />
            <img src={image3} alt="...." className="ml-auto mr-auto" />
          </div>

          <div className="my-4">
            <Link to="/contact-us">
              <button
                className="w-full mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
              >
                <p className="textBottomAgendProducts">
                  {i18n.t("textServices.textNew12")}
                </p>
              </button>
            </Link>
          </div>


        </div>
      )}
    </div>
  )
}

export default ManageServicesInfo
