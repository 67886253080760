import React, { useContext } from "react"
import { LoadingContext } from "../../components/context/LoadingContext"
import { Link } from "gatsby"
import i18n from "i18next"

const TargetWork = ({ workArray, finalValue, initialValue }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div
      className={
        windowWidth >= 1700
          ? "desktop:flex viewOtherVacantsResponsive"
          : "desktop:flex justifyStyle"
      }
    >
      {windowWidth >= 1025 ? (
        <>
          {workArray.slice(initialValue, finalValue).map((item, index) => {
            return (
              <div className="targetWork ">
                <div className="targetWorkTitle">
                  <p className="ubicationWorkHome">
                    {item.jobLocation.cityName +
                      "," +
                      item.jobLocation.countryName}
                  </p>
                  <img className='mt-4 mb-1' src={item.vacancyImage} alt="Vacancy" loading="lazy"/>
                  <p className={windowWidth >1700 ? "titleWorkHome mt-5":"titleWorkHome2 mt-5"}>{item.name}</p>
                  <p className="typeWork mt-2 ">{item.time}</p>
                </div>

                <div className="contentDescriptionWork">
                  <p className="descriptionWorkHome">{item.description}</p>
                </div>
                <Link to={`/open-position/${item.jobId}`}>
                  <button
                    className="contentButtomApplyVacancie"
                    style={{ alignSelf: "center" }}
                  >
                    <p className="textButtomGetTouch">
                      {i18n.t("pagejoinourteam.targetVacancies.apply")}
                    </p>
                  </button>
                </Link>
              </div>
            )
          })}
        </>
      ) : (
        <>
          {workArray.slice(initialValue, finalValue).map((item, i) => {
            return (
              <div className="targetWorkMobile ">
                <div className="targetWorkTitle">
                  <p className="ubicationWork">
                    {item.jobLocation.cityName +
                      "," +
                      item.jobLocation.countryName}
                  </p>
                  <img className='mt-4 mb-1' src={item.vacancyImage} alt="Vacancy" loading="lazy"/>
                  <p className="titleWork mt-5">{item.name}</p>
                  <p className="typeWork mt-2 ">{item.time}</p>
                </div>

                <div className="contentDescriptionWork">
                  <p className="descriptionWorkHome">{item.description}</p>
                </div>
                <Link to={`/open-position/${item.jobId}`}>
                  <button
                    className="contentButtomApplyVacancie ml-auto"
                    style={{ alignSelf: "center" }}
                  >
                    <p className="textButtomGetTouch">
                      {i18n.t("pagejoinourteam.targetVacancies.applynow")}
                    </p>
                  </button>
                </Link>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export default TargetWork
