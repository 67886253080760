import React, { Fragment, useState, useContext } from "react"
import image1 from "../../images/servicesExpansion/Grupo 476.png"
import { LoadingContext } from "../context/LoadingContext"
import { Link } from "gatsby"
import i18n from "i18next"
import icon50 from "../../images/servicesExpansion/50.png"
import icon204 from "../../images/servicesExpansion/204.png"
import icon600 from "../../images/servicesExpansion/600.png"
import icon1 from "../../images/servicesExpansion/horizons01.png"
import icon2 from "../../images/servicesExpansion/horizons02.png"
import icon3 from "../../images/servicesExpansion/horizons03.png"
import icon4 from "../../images/servicesExpansion/horizons04.png"
import img1 from "../../images/servicesExpansion/imgMobile/image1.png"
import img2 from "../../images/servicesExpansion/imgMobile/image2.png"
import img3 from "../../images/servicesExpansion/imgMobile/image3.png"
import img4 from "../../images/servicesExpansion/imgMobile/image4.png"
import VideoBannerServices from "./VideoBannerServices";
import NumericInfoServices from "./NumericInfoServices"


const BannerExpansionServices = ({ bannerImageURL, titleHorizons }) => {
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)


  const horizonsImagen = [icon1, icon2, icon3, icon4]
  const horizonsImgMobile = [img1, img2, img3, img4]
  return (
    <Fragment>
      <div className="container-banner-mainProducts">
        <div className="">
          {windowWidth >= 1025 ? (
            <div className="flex mobile:flex-col-reverse desktop:flex-row">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="shared-banner-content-btnAcademy m-4 desktop:mt-4 mobile:mt-16 "
              >
                <div className="text-left  text-white mb-32 tablet:mb-0">
                    <p className="text-56 text-center desktop:text-64 bigdesktop:text-80 mt-4 font-byom-bold">
                    {i18n.t("textServices.textNew")}
                    </p>

                    <p className="bannerServicesVideo">
                    {i18n.t("textServices.textNew9")}
                    </p>
                </div>

                <div className="text-left text-center text-white mb-32 tablet:mb-0">
                    <img
                    src={image1}
                    alt="Imagen PopUp"
                    className="ml-auto mr-auto mt-16"
                  />
                </div>

                <div className="mt-4">
                  <p className="bannerServicesText">
                      {i18n.t("pagemoreservices.expansionServices.description1")}
                        </p>
                        <p className="bannerServicesText">
                      {i18n.t("pagemoreservices.expansionServices.description2")}
                        </p>
                </div>

                <div>
                <NumericInfoServices/>
                </div>

              </div>
            </div>
          ) : (
            <div className="">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="ml-5 tablet:mx-4 mobile:my-48 text-white "
              >
                
              </div>
              <div className="text-left  text-white mb-32 tablet:mb-0">
                    <p className="text-56 text-center desktop:text-64 bigdesktop:text-80 mt-4 font-byom-bold">
                    {i18n.t("textServices.textNew")}
                    </p>

                    <p className="bannerServicesVideo">
                    {i18n.t("textServices.textNew9")}
                    </p>
                </div>

                <div className="text-left text-center text-white mb-32 tablet:mb-0">
                    <img
                    src={image1}
                    alt="Imagen PopUp"
                    className="ml-auto mr-auto mt-16"
                  />
                </div>

                <div className="mt-4">
                  <p className="bannerServicesText">
                      {i18n.t("pagemoreservices.expansionServices.description1")}
                        </p>
                        <p className="bannerServicesText">
                      {i18n.t("pagemoreservices.expansionServices.description2")}
                        </p>
                </div>

                <div>
                <NumericInfoServices/>
                </div>
            </div>
          )}

        </div>
      </div>
    </Fragment>
  )
}

BannerExpansionServices.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
}

export default BannerExpansionServices
