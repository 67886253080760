import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/products/ImageTalent1.png"
import image2 from "../../images/products/ImageTalent.png"
import { Link } from "gatsby"

const AboutThisProduct = () => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className="">
      {windowWidth >= 1025 ? (
        <div className="flex w-full contentApply">
          <div className="  mt-10 mx-12 ">
            <div className="">
              <img src={image1} alt="...." className="ml-auto" />
            </div>

            <div className=" imgExsisAcademy">
              <img src={image2} alt="...." className="" />
            </div>
          </div>
          <div className="contentWhyUs bigdesktop:w-1/2">
            <div className="eventRightTimeLineMobile">
              <p className="titleInfoExsisAcademy">
                {i18n.t("AboutThisProduct.title")}
              </p>
            </div>

            <p className="textInfoExsisAcademy2 bigdesktop:w-full mt-10 mb-8">
              {i18n.t("AboutThisProduct.text1")}
            </p>
            <p className="textInfoExsisAcademy2 bigdesktop:w-full">
              {i18n.t("AboutThisProduct.text2")}
            </p>
            <div className="mt-8">
              <Link to={"/contact-us"}>
                <button className="buttonLetsTalk flex justify-start items-center h-11 w-48">
                  <span className="mx-auto">
                    {i18n.t("AboutThisProduct.button1")}
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className=" w-full">
          <div className="mt-10 divInfoExsisAcademyBootcampsMobile">
            <div className="">
              <img src={image2} alt="...." className="" />
            </div>

            <div className="mt-4">
              <img src={image1} alt="...." className="" />
            </div>
          </div>
          <div className="contentWhyUs divInfoExsisAcademyMobile">
            <div className="eventRightTimeLineMobile">
              <p className="titleInfoExsisAcademy">
                {i18n.t("AboutThisProduct.title")}
              </p>
            </div>

            <p className="textInfoExsisAcademy2Mobile mt-8">
              {i18n.t("AboutThisProduct.text1")}
            </p>
            <br />
            <p className="textInfoExsisAcademy2Mobile ">
              {i18n.t("AboutThisProduct.text2")}
            </p>
            <div className="mt-8">
              <Link to={"/contact-us"}>
                <button className="buttonLetsTalk flex justify-start items-center h-11 w-full">
                  <span className="mx-auto">
                    {i18n.t("AboutThisProduct.button1")}
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AboutThisProduct
