import React, { useState, useContext, useEffect } from "react"
import i18n from "i18next"
import SliderArticles from "./SliderArticles"
import { LoadingContext } from "../../components/context/LoadingContext"
import SliderBlogs from "./SliderBlogs"
import { getBlogsPosts, getBlogsRef } from "../../utils/api"

const Articles = ({}) => {
  const { windowWidth } = useContext(LoadingContext)
  const [blogs, setBlogs] = useState([
    {
      category: "",
      image: "",
      title: "",
      id: "",
      data: "",
    },
  ])
  const [menu, setMenu] = useState(1)
  const [articlesArray, setArticlesArray] = useState([])

  const getRef = async () => {
    try {
      const response = await getBlogsRef()
      const jsonData = response.data
      const refValue = jsonData.refs[0].ref
      return refValue
    } catch (error) {
      console.error("Error al realizar la solicitud:", error.message)
      throw error
    }
  }

  const getBlogs = async () => {
    try {
      const lang = i18n.language === "en" ? "&lang=en-us" : ""
      const ref = await getRef()
      const response = await getBlogsPosts(ref + lang)
      const jsonData = response.data
      const results = jsonData.results

      const mappedBlogs = results.map(result => ({
        category: result.data.blog_category,
        image: result.data.thumbnail_image.url,
        title: result.data.blog_title[0].text,
        id: result.id,
        data: result.data.text_and_images,
        date: new Date(result.last_publication_date),
      }))
      const blogsOrdenados = mappedBlogs.sort((a, b) => b.date - a.date)
      setBlogs(blogsOrdenados)
    } catch (error) {
      console.error("Error al realizar la solicitud:", error.message)
    }
  }

  useEffect(() => {
    getBlogs()
  }, [i18n.language])

  useEffect(() => {
    if (menu == 1) {
      setArticlesArray(blogs)
    } else if (menu == 2) {
      setArticlesArray(blogs.filter(option => option.category == "Business"))
    } else if (menu == 3) {
      setArticlesArray(blogs.filter(option => option.category == "Data Base"))
    } else if (menu == 4) {
      setArticlesArray(blogs.filter(option => option.category == "Engineering"))
    } else if (menu == 5) {
      setArticlesArray(blogs.filter(option => option.category == "IT Security"))
    } else if (menu == 6) {
      setArticlesArray(blogs.filter(option => option.category == "Software Development"))
    }
  }, [menu, blogs])

  const menuItems = [
    { id: 1, label: "All" },
    { id: 2, label: "Business" },
    { id: 3, label: "Data Base" },
    { id: 4, label: "Engineering" },
    { id: 5, label: "IT Security" },
    { id: 6, label: "Software Development" },
  ]

  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card paddingArticles">
      <div className="flip-card-inner2 desktop:ml-16 mobile:ml-4 mt-24">
        <div className="desktop:flex">
          <div>
            <p className="subTitleOurServices">x media</p>
            <p className="titleOurServices">
              {i18n.t("home.ArticlesXmedia.title")}
            </p>
          </div>
          <div className="desktop:flex MenuArticles desktop:mr-24 bigdesktop:mr-48 mobile:flex mobile:flex-wrap mobile:mt-8">
            <button
              className={
                menu == "1"
                  ? "contentMenuArticles"
                  : "contentMenuArticlesNoSelect"
              }
              onClick={() => setMenu(1)}
              style={{ alignSelf: "center" }}
            >
              <p
                className={
                  menu == "1" ? "textMenuArticles2" : "textMenuArticlesNoSelect"
                }
              >
                {i18n.t("pageMediaBlogArticle.sidebar.categories.all")}
              </p>
            </button>

            <button
              className={
                menu == "2"
                  ? "contentMenuArticles"
                  : "contentMenuArticlesNoSelect"
              }
              onClick={() => setMenu(2)}
              style={{ alignSelf: "center" }}
            >
              <p
                className={
                  menu == "2" ? "textMenuArticles2" : "textMenuArticlesNoSelect"
                }
              >
                {i18n.t("pageMediaBlogArticle.sidebar.categories.category1")}
              </p>
            </button>

            <button
              className={
                menu == "3"
                  ? "contentMenuArticles"
                  : "contentMenuArticlesNoSelect"
              }
              onClick={() => setMenu(3)}
              style={{ alignSelf: "center" }}
            >
              <p
                className={
                  menu == "3" ? "textMenuArticles2" : "textMenuArticlesNoSelect"
                }
              >
                {i18n.t("pageMediaBlogArticle.sidebar.categories.category2")}
              </p>
            </button>

            <button
              className={
                menu == "4"
                  ? "contentMenuArticles"
                  : "contentMenuArticlesNoSelect"
              }
              onClick={() => setMenu(4)}
              style={{ alignSelf: "center" }}
            >
              <p
                className={
                  menu == "4" ? "textMenuArticles2" : "textMenuArticlesNoSelect"
                }
              >
                {i18n.t("pageMediaBlogArticle.sidebar.categories.category4")}
              </p>
            </button>

            <button
              className={
                menu == "5"
                  ? "contentMenuArticles"
                  : "contentMenuArticlesNoSelect"
              }
              onClick={() => setMenu(5)}
              style={{ alignSelf: "center" }}
            >
              <p
                className={
                  menu == "5" ? "textMenuArticles2" : "textMenuArticlesNoSelect"
                }
              >
                {i18n.t("pageMediaBlogArticle.sidebar.categories.category3")}
              </p>
            </button>

            <button
              className={
                menu == "6"
                  ? "contentMenuArticles"
                  : "contentMenuArticlesNoSelect"
              }
              onClick={() => setMenu(6)}
              style={{ alignSelf: "center" }}
            >
              <p
                className={
                  menu == "6" ? "textMenuArticles2" : "textMenuArticlesNoSelect"
                }
              >
                {i18n.t("pageMediaBlogArticle.sidebar.categories.category5")}
              </p>
            </button>
          </div>
        </div>
        <div className="desktop:flex desktop:pr-4 mb-8">
          <div className="">
            <p
              className={
                windowWidth >= 1025
                  ? "textCoreSolutions widthResponsive desktop:text-20 mobile:text-16 desktop:text-left  mb-8 mobile:px-6"
                  : "textCoreSolutionsMobile desktop:text-20 mobile:text-16 text-left  mb-8 mobile:px-6"
              }
            >
              {i18n.t("home.ArticlesXmedia.text")}
            </p>
          </div>
          <div className="desktop:flex ml-auto"></div>
        </div>
        {articlesArray.length === 0 && (
          <div className="noFoundAricles">
            <p className="textNoFoundAricles">
              {" "}
              {i18n.language == "en" ? "No Blogs Found" : "No se encontraron blogs"}
            </p>
          </div>
        )}
        <SliderBlogs articlesArray={articlesArray}/>
      </div>
    </div>
  )
}

export default Articles
