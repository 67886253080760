import React, { useState, useContext, useEffect, useRef } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import arrowLeft from "./../../images/ourDNA/OurNewDNA/Grupo439.png"
import arrowRigth from "./../../images/ourDNA/OurNewDNA/Grupo440.png"
import { LoadingContext } from "../context/LoadingContext"
import Miguel from "./../../images/ourDNA/OurNewDNA/Miguel-Jimenez.png"
import Sebastian from "./../../images/ourDNA/OurNewDNA/Sebastian-Jimenez.png"
import Santiago from "./../../images/ourDNA/OurNewDNA/Santiago-Jimenez.png"
import Yuli from "./../../images/ourDNA/OurNewDNA/Yuli-Rios.png"
import Diana from "./../../images/ourDNA/OurNewDNA/Diana-Pulido.png"
import Luisa from "./../../images/ourDNA/OurNewDNA/Luisa-Galindo.png"
import Cristian from "./../../images/ourDNA/OurNewDNA/Cristian-Abril.png"
import Julian from "./../../images/ourDNA/OurNewDNA/Julian-Bonilla.png"
import Hernan from "./../../images/ourDNA/OurNewDNA/Hernan-Fagua.png"
import JuanAguirre from "./../../images/ourDNA/OurNewDNA/Juan-Carlos-Aguirre.png"
import Isabela from "./../../images/ourDNA/OurNewDNA/Isabela-Mogollon.png"
import Maira from "./../../images/ourDNA/OurNewDNA/Maira-Lopez.png"
import Marcela from "./../../images/ourDNA/OurNewDNA/Marcela-Camargo.png"
import karol from "./../../images/ourDNA/OurNewDNA/Karol-Andrade.png"
import laura from "./../../images/ourDNA/OurNewDNA/Laura-Gutierrez.png"
import osiris from "./../../images/ourDNA/OurNewDNA/Osiris-Ibague.png"


import Miguel2 from "./../../images/ourDNA/OurNewDNA/Ingles/Miguel-Jimenez.png"
import Sebastian2 from "./../../images/ourDNA/OurNewDNA/Ingles/Sebastian-Jimenez.png"
import Santiago2 from "./../../images/ourDNA/OurNewDNA/Ingles/Santiago-Jimenez.png"
import Yuli2 from "./../../images/ourDNA/OurNewDNA/Ingles/Yuli-Rios.png"
import Diana2 from "./../../images/ourDNA/OurNewDNA/Ingles/Diana-Pulido.png"
import Luisa2 from "./../../images/ourDNA/OurNewDNA/Ingles/Luisa-Galindo.png"
import Cristian2 from "./../../images/ourDNA/OurNewDNA/Ingles/Cristian-Abril.png"
import Julian2 from "./../../images/ourDNA/OurNewDNA/Ingles/Julian-Bonilla.png"
import Hernan2 from "./../../images/ourDNA/OurNewDNA/Ingles/Hernan-Fagua.png"
import JuanAguirre2 from "./../../images/ourDNA/OurNewDNA/Ingles/Juan-Aguirre.png"
import Isabela2 from "./../../images/ourDNA/OurNewDNA/Ingles/Isabella-Mogollon.png"
import Maira2 from "./../../images/ourDNA/OurNewDNA/Ingles/Maira-Lopez.png"
import Marcela2 from "./../../images/ourDNA/OurNewDNA/Ingles/Marcela-Camargo.png"
import karol2 from "./../../images/ourDNA/OurNewDNA/Ingles/Karol-Andrade.png"
import laura2 from "./../../images/ourDNA/OurNewDNA/Ingles/Laura-Gutierrez.png"
import osiris2 from "./../../images/ourDNA/OurNewDNA/Ingles/Osiris-Ibague.png"

const OurTeam = ({ }) => {
  const { windowWidth } = useContext(LoadingContext)
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)
  const [hoveredIndex, setHoveredIndex] = useState(-1)

  const team = [
    {
      image: Miguel,
      linkedIn:
        "https://www.linkedin.com/in/miguel-angel-jimenez-nu%C3%B1ez-60a76a7/",
      imageEnglish: Miguel2,
    },
    {
      image: Sebastian,
      linkedIn:
        "https://www.linkedin.com/in/sebastian-jimenez-pedraza-37884093/",
        imageEnglish: Sebastian2,
    },
    {
      image: Santiago,
      linkedIn: "https://www.linkedin.com/in/santiago-jim%C3%A9nez-23797616b/",
      imageEnglish: Santiago2,
    },
    {
      image: Diana,
      linkedIn: "https://www.linkedin.com/in/dianapulidovillarraga/",
      imageEnglish: Diana2,
    },
    {
      image: Isabela,
      linkedIn: "https://www.linkedin.com/in/isabela-mogoll%C3%B3n-caicedo-27232b22b/",
      imageEnglish: Isabela2,
    },
    {
      image: Maira,
      linkedIn: " https://www.linkedin.com/in/maira-lopez-3405001a8/",
      imageEnglish: Maira2,
    },
    {
      image: Marcela,
      linkedIn: "https://www.linkedin.com/in/paola-marcela-camargo-4575a724b/",
      imageEnglish: Marcela2,
    },
    {
      image: Luisa,
      linkedIn: "https://www.linkedin.com/in/luisagalindo/",
      imageEnglish: Luisa2,
    },
    {
      image: Cristian,
      linkedIn: "https://www.linkedin.com/in/cristian-abril-35776b188/",
      imageEnglish: Cristian2,
    },
    {
      image: Julian,
      linkedIn: "https://www.linkedin.com/in/julian-bonilla-02a466180/",
      imageEnglish: Julian2,
    },
    {
      image: Hernan,
      linkedIn: "https://www.linkedin.com/in/hernan-fagua-0428471b5/",
      imageEnglish: Hernan2,
    },
    {
      image: JuanAguirre,
      linkedIn:
        "https://www.linkedin.com/in/juan-carlos-aguirre-forero-a61b971a8/",
        imageEnglish: JuanAguirre2,
    },
    {
      image: Yuli,
      linkedIn: "https://www.linkedin.com/in/andrea-rios-tapiero-2672a3211/",
      imageEnglish: Yuli2,
    },
    {
      image: karol,
      linkedIn: "http://linkedin.com/in/karol-andrade-5b1381163",
      imageEnglish: karol2,
    },
    {
      image: laura,
      linkedIn: "https://www.linkedin.com/in/laura-guti%C3%A9rrez-riveros-08544090/ ",
      imageEnglish: laura2,
    },
    {
      image: osiris,
      linkedIn: "https://www.linkedin.com/in/osirisnatali22/",
      imageEnglish: osiris2,
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    autoPlay: true,
    loop: true,
    arrows: true,
    autoPlaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    speed: 1000,
    fade: false,
  }

  const settingsMobile = {
    dots: false,
    infinite: true,
    autoPlay: true,
    loop: true,
    arrows: false,
    autoPlaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 1000,
    fade: false,
    dotsClass: "slick-dots blue-dark-slide-dots--small hidden-desktop__dot",
    transition: "all 0.2s",
  }

  const goToSlide = index => {
    setCurrentIndex(index)
    if (carouselRef.current) {
      if (index < currentIndex) {
        carouselRef.current.slickPrev()
      } else if (index > currentIndex) {
        carouselRef.current.slickNext()
      }
    }
  }

 

  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-20 mobile:pl-4 flip-card pb-20">
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 desktop:ml-16 mobile:ml-4 mt-24">
          <div className=" ">
            <div className="countTextCountMobile">
              <p className="textAboutUs">
                {i18n.t("OurExperienceWhyUs.subtitleMeet")}
              </p>
              <p className={"titleOurServices " }>
                {i18n.t("OurExperienceWhyUs.titleMeet")}
              </p>
            </div>

          </div>
          <div className="mt-10">

            <Slider {...settings} ref={carouselRef}>
              {team.map((item, i) => {
                return (
                  <div key={i.toString()}>
                  <div className="pr-5">
                    <a href={item.linkedIn} target="_blank">
                    {i18n.language == "es" ?
                      <img src={item.image} alt="...." />:
                      <img src={item.imageEnglish} alt="...." />
                    }                    

                    </a>
                  </div>
                </div>
                );
              })}
            </Slider>
        
          </div>
        </div>
      ) : (
        <div>
          <div className="countTextCountMobile">
            <p className="textAboutUs">
              {i18n.t("OurExperienceWhyUs.subtitleMeet")}
            </p>
            <p
              className={
                i18n.language == "en"
                  ? "titleOurServices contentTitleTeams"
                  : "titleOurServices contentTitleTeamsSpanish w-11/12"
              }
            >
              {i18n.t("OurExperienceWhyUs.titleMeet")}
            </p>
          </div>
          <div className="mt-5">
            <Slider {...settingsMobile} ref={carouselRef}>
              {team.map((item, i) => {
                return (
                  <div key={i.toString()}>
                    <div className="pr-5">
                      <a href={item.linkedIn} target="_blank">
                      {i18n.language == "es" ?
                        <img src={item.image} alt="...." />:
                        <img src={item.imageEnglish} alt="...." />
                      }
                      </a>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
          <div className="flex justify-center gap-16  mt-5">
            <button onClick={() => goToSlide(currentIndex - 1)}>
              <img src={arrowLeft} alt="left" className="mt-1" />
            </button>
            <button onClick={() => goToSlide(currentIndex + 1)}>
              <img src={arrowRigth} alt="right" />
            </button>
          </div>
        </div>
      )}

    </div>
  )
}

export default OurTeam
