import React, { useState, useEffect, useContext, useRef } from "react";
import { LoadingContext } from "../context/LoadingContext";
import Slider from "react-slick";
import { Link } from "gatsby";
import limpiarTexto from "../xMediaBlogArticle/utilsBlogs";
import i18n from "i18next";
import arrowLeft from "../../images/home/arrowLeft.png"
import arrowRigth from "../../images/home/arrowRigth.png"

function SliderBlogs({ articlesArray }) {
  const { windowWidth } = useContext(LoadingContext);
  const sliderRef = useRef(null); // Referencia al slider
  const [settingsScreen, setSettingsScreen] = useState(false);

  const settings = {
    dots: false,
    autoPlay: true,
    infinite: false,
    arrows: false,
    autoPlaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    speed: 1000,
    fade: false,
    transition: "all 0.2s",
  };

  const settingsMobile = {
    dots: false,
    autoPlay: true,
    infinite: false,
    arrows: false,
    autoPlaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 1000,
    fade: false,
    transition: "all 0.2s",
  };

  const handleBefore = () => {
    sliderRef.current.slickPrev(); // Mueve a la diapositiva anterior
  };

  const handleAfter = () => {
    sliderRef.current.slickNext(); // Mueve a la diapositiva siguiente
  };

  useEffect(() => {
    if (windowWidth >= 800) {
      setSettingsScreen(settings);
    } else {
      setSettingsScreen(settingsMobile);
    }
  }, [windowWidth]);

  return (
    <>
      {/* Slider */}
      <Slider {...settingsScreen} ref={sliderRef}>
        {articlesArray.map((card) => (
          <Link
            key={card.id}
            to={`/blogs/${i18n.language}/${limpiarTexto(card.title)}/`}
          >
            <div
              className={
                windowWidth >= 1920
                  ? "cardMediaBlogBigDesktop"
                  : windowWidth > 1020
                  ? "cardMediaBlog"
                  : "cardMediaBlogMobile"
              }
              key={card.id}
            >
              <div
                className={
                  windowWidth >= 1920 ? "cardMediaBlogImage" : undefined
                }
                style={{
                  backgroundImage: "url(" + card.image + ")",
                }}
              >
                <div
                  className={
                    windowWidth >= 1920
                      ? "contentTextCardArticlesBigDesktop flex flex-col justify-end pb-12"
                      : windowWidth > 1020
                      ? "contentTextCardArticles flex flex-col justify-end pb-12"
                      : "contentTextCardArticlesMobile flex flex-col justify-end pb-8"
                  }
                  style={{
                    background:
                      "linear-gradient(0deg, #000538 15%, rgba(0, 0, 0, 0) 65%)",
                  }}
                >
                  <div className="text-left w-auto ">
                    <p className="pr-2 pl-2 titleCardArticle text-12 desktop:text-20">
                      {card.title}
                    </p>
                    <p className="pr-2 pl-2 textCardArticle desktop:text-20">
                      {card.category}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </Slider>
      {/* Botones de flechas */}
      <div className={windowWidth>1025? "flex ml-auto mt-4 mb-4  pr-16": "flex ml-auto mt-4 mb-4"}>
        <button className="mobile:ml-auto" onClick={handleBefore}>
          <img src={arrowLeft} alt="Flecha Izquierda" className="mt-1 desktop:mr-16 mobile:mr-24"/>
        </button>
        <button className="mobile:mr-auto" onClick={handleAfter}>
          <img src={arrowRigth} alt="Flecha Derecha" className="" />
        </button>
      </div>
    </>
  );
}

export default SliderBlogs;
