import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/products/businessman-social-networks-concept.png"
import image2 from "../../images/products/colleagues-thinking-with-post-its-side-view.png"
import image3 from "../../images/products/corporate-workers-brainstorming-together.png"
import image4 from "../../images/servicesExpansion/Grupo334.png"
import { Link } from "gatsby"
import xElement from "../../images/servicesExpansion/Grupo332.png"
import xElement1 from "../../images/servicesExpansion/Grupo344.png"

const Conection = ({ }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div
      className={windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card "}
    >

      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 p-16">
          <div className="flex">
            <div className="tech-section">


              <div className="mt-8">
                <p className="textTalentNew mt-4">
                {i18n.t("textServices.textNew91")}
                </p>

                <p className="textTalentNew mt-4">
                {i18n.t("textServices.textNew92")}
                </p>


                {/* Párrafo adicional con imagen como separador */}
                <div className="mt-6 flex items-center">
                  <img src={image4} alt="Índice 1" className="separator-image" />
                  <p className="textTalentNew ml-4">
                  {i18n.t("textServices.textNew93")}
                  </p>
                </div>

                <div className="mt-6 flex items-center">
                  <img src={image4} alt="Índice 2" className="separator-image" />
                  <p className="textTalentNew ml-4">
                  {i18n.t("textServices.textNew94")}
                  </p>
                </div>

                <div className="mt-6 flex items-center">
                  <img src={image4} alt="Índice 3" className="separator-image" />
                  <p className="textTalentNew ml-4">
                  {i18n.t("textServices.textNew95")}
                  </p>
                </div>
              </div>

              <div className="contentViewMoreProducts mt-8">
                <Link to="/contact-us">
                  <button
                    className="mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                    style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                  >
                    <p className="textBottomAgendProducts">
                    {i18n.t("textServices.textNew12")}
                    </p>
                  </button>
                </Link>
              </div>

            </div>
            <div className="mt-10 tech-section justify-center w-1/2 mx-auto">

              <div className="flex w-full justify-start">
                <img className="w-1/3 desktop:w-fit styleXelementTalent xElementSoftware " src={xElement} alt="xs" />
              </div>

              <div className="flex">
                <img src={image1} alt="..." className="w-1/2" />

                <div className="w-1/2">
                  <div className="inline-block">
                    <img src={image2} alt="..." className="w-full imagenFabrica" />
                  </div>
                  <div className="inline-block">
                    <img src={image3} alt="..." className="w-full imagenFabrica" />
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-end pr-32">
                <img className="w-1/3 desktop:w-fit styleXelementTalent imageBlockchain3 xElemtenStaff" src={xElement1} alt="xs" />
              </div>
            </div>


          </div>

        </div>
      ) : (
        <div className="flip-card-inner2">
          
          <div className="">
                <p className="textTalentNew mt-4">
                {i18n.t("textServices.textNew91")}
                </p>

                <p className="textTalentNew mt-4">
                {i18n.t("textServices.textNew92")}
                </p>


                {/* Párrafo adicional con imagen como separador */}
                <div className="mt-6 flex items-center">
                  <img src={image4} alt="Índice 1" className="separator-image" />
                  <p className="textTalentNew ml-4">
                  {i18n.t("textServices.textNew93")}
                  </p>
                </div>

                <div className="mt-6 flex items-center">
                  <img src={image4} alt="Índice 2" className="separator-image" />
                  <p className="textTalentNew ml-4">
                  {i18n.t("textServices.textNew94")}
                  </p>
                </div>

                <div className="mt-6 flex items-center">
                  <img src={image4} alt="Índice 3" className="separator-image" />
                  <p className="textTalentNew ml-4">
                  {i18n.t("textServices.textNew95")}
                  </p>
                </div>
              </div>

          <div className="mt-10 ">
            <img src={image1} alt="...." className="ml-auto mr-auto" />
            <img src={image2} alt="...." className="ml-auto mr-auto" />
            <img src={image3} alt="...." className="ml-auto mr-auto" />
          </div>

          <div className="my-4">
            <Link to="/contact-us">
              <button
                className="w-full mobile:mt-4 bottomViewMoreProducts conButtomMoreAcademy ml-auto mr-auto"
                style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
              >
                <p className="textBottomAgendProducts">
                {i18n.t("textServices.textNew12")}
                </p>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default Conection
