import React, { Fragment, useState, useContext } from "react"
import { Link } from "gatsby"
import { LoadingContext } from "../context/LoadingContext"
import i18n from 'i18next';
import imageFondo from "../../images/servicesExpansion/Grupo493.png";

const ManageServices = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className={ windowWidth >= 1025 ? "mobile:w-full flip-card " : "mobile:w-full desktop:pl-8 desktop:pr-4 flip-card "}>
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2 flex sharedBannerManage">
          <div className="ml-4">
          <img src={imageFondo} alt="...." />
          </div>
          <div className="ml-12 mt-auto mb-auto">
            <p className="titleBannerAcademy">{i18n.t("textServices.textNew5")}</p>
          </div>
        </div>
      ) : (
        <div className="flip-card-inner2 sharedBannerManage  mt-24 mb-24">
          <div>
            <p className="titleBannerAcademy">{i18n.t("textServices.textNew5")}</p>
            
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageServices
