
import i18n from 'i18next';
import React, { useState, useEffect,useContext } from 'react';

import { LoadingContext } from '../../components/context/LoadingContext';
import arrowLeft from "../../images/ourDNA/Group29.png"
import arrowRigth from "../../images/ourDNA/Group30.png"
import ReactPlayer from 'react-player/lazy';

import videoJuan from './../../images/VideosTeamMembers/JuanHerrerasub.mp4';
import videoCristian from './../../images/VideosTeamMembers/CristianZambranosub.mp4';
import videoFelipe from './../../images/VideosTeamMembers/FelipeArcesub.mp4';
import videoDanilo from './../../images/VideosTeamMembers/DaniloJiménezsub.mp4';

import videoCristianAbril from './../../images/VideosTeamMembers/VideoCristianAbrilsub.mp4';
import videoYeimit from './../../images/VideosTeamMembers/VideoYeimitMirandasub.mp4';
import videoBonilla from './../../images/VideosTeamMembers/VideoJuliánBonillasub.mp4';
import videoJohamed from './../../images/VideosTeamMembers/johamedsub.mp4';
import videoPeña from './../../images/VideosTeamMembers/VideoJuliánpeñasub.mp4';

const itemsEnglish = [
	{
		"video": "./../../images/VideosTeamMembers/JuanHerrerasub.mp4",
		"name": "Juan Herrera",
		"position": "Systems Analyst",
		"description": "\"My experience in Exsis has been very fulfilling, because here I could start my career as an IT professional, giving me not only the chance to learn and develop new skills but also to work and study at the same time because of the flexibility in the work schedule and also because of the remote work possibility. I'm very happy to be a part of this team and I think I can grow a lot in this company\"."
	},
    {
		"video": './../../images/VideosTeamMembers/CristianZambranosub.mp4',
		"name": "Cristian Zambrano",
		"position": "Junior Developer",
		"description": "\"Hello, my name is Cristian Zambrano. I am proud to be a part of the Exsis Digital Angels team. What stands out most in our company is the spirit of collaboration that each of our partners brings to the table. It is truly fulfilling to work with people committed to innovation and the future of technology. So join us in this exciting journey\"."
	},
    {
		"video": './../../images/VideosTeamMembers/DaniloJiménezsub.mp4',
		"name": "Danilo Jiménez",
		"position": "Systems Analyst",
		"description": "\"Hello, my name is Danilo, and I'm a Systems Engineer at Exsis. During my time here, I had the opportunity to work in a dynamic and exciting work environment with a great team of experts who helped me in my professional growth. Today, I would like to share my experience specially in the Factory department, where I had the chance to be immersed into the development and production processes of highly interesting products\"."
	},

]

const itemsEspanish = [
	{
		"video": videoCristianAbril,
		"name": "Cristian Abril",
		"position": "Líder de Evaluación y Mejora",
		"description": "\"Mi experiencia en Exsis ha sido muy gratificante, aquí he logrado aplicar muchos de los conocimientos que he adquirido en la academia. Exsis me ha formado como un gran profesional, estoy agradecido con la empresa porque me recibió desde un perfil muy junior, con pocos conocimientos del sector Ti. Desde mi roll en la gestión de evaluación y mejora, apoyo a la empresa a certificarse en estándares y modelos de calidad que nos brinda una ventaja competitiva\"."
	},
    {
		"video": videoYeimit,
		"name": "Yeimit Miranda",
		"position": "Analista Contable",
		"description": "\"Me siento muy contenta de pertenecer a la familia Exsis, ya que es una compañía que se interesa por el bienestar de sus empleados. Además, tanto la compañía como mi equipo siempre me han motivado y me han apoyado para seguir cumpliendo mis sueños, al avanzar más tanto en la parte personal, como en la profesional\"."
	},
    {
		"video": videoBonilla,
		"name": "Julián Bonilla",
		"position": "Líder de Infraestructura",
		"description": "\"Gracias a Exsis he crecido tanto personal y profesionalmente, iniciando como un analista, desempeñándome día a día por mejorar con calidad mi desempeño en mis labores que ejecuto diariamente. Gracias a una comunicación asertiva con mi equipo de trabajo, hemos logrado que la experiencia con base al soporte de usuario sea cada vez mejor y de un amanera más efectiva. Gracias también a Exsis en mi parte profesional, me ha permitido estudiar y capacitarme y hoy en día es lo que me tiene siendo líder del área\"."
	},
    {
		"video": videoPeña,
		"name": "Julián Peña",
		"position": "Desarrollador Senior",
		"description": "\"En Exsis he tenido la oportunidad de ampliar mis conocimientos en cada proyecto que he estado, me ha servido como escuela para fortalecer cada tecnología en la que he trabajado. Exsis es una gran familia, nos apoyamos por sacar los proyectos adelante siempre cumpliendo los objetivos y metas propuestas\"."
	},

]



const CustomerTestimonials = () => {
	const { windowWidth } = useContext(LoadingContext);

	const [currentIndex, setCurrentIndex] = useState(0);
	const [currentIndexES, setCurrentIndexES] = useState(0);

	const nextSlide = () => {
	  setCurrentIndex(prevIndex => (prevIndex + 1) % itemsEnglish.length)
	}
  
	const prevSlide = () => {
	  setCurrentIndex(
		prevIndex => (prevIndex - 1 + itemsEnglish.length) % itemsEnglish.length
	  )
	}

	const nextSlideES = () => {
	  setCurrentIndexES(prevIndex => (prevIndex + 1) % itemsEspanish.length)
	}
  
	const prevSlideES = () => {
	  setCurrentIndexES(
		prevIndex => (prevIndex - 1 + itemsEspanish.length) % itemsEspanish.length
	  )
	}

	return (
		<div className=" mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card seccionTeamExpert">
			{	windowWidth >= 1025 ? (<>
			
			<div className='titleContentExpert'>
			<p className='titleTeamExperts'>{i18n.t('OurExperienceWhyUs.titleTeams')}</p> 
			<p className='titleTeamExperts'>{i18n.t('OurExperienceWhyUs.titleTeams2')}</p>
			</div>
			<div className={windowWidth > 1700 ? "flex justifyStyle contentSeccionTeamsResponsive": "flex justifyStyle contentSeccionTeams"}>
		
			<div className='flex' >
				
				{i18n.language == "en" ? <>
				{currentIndex == 0 ?
						<div className='contentVideosTexti'>
				
						<ReactPlayer
						width='23rem'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoJuan} />
				   
						</div>: null
				}
				
				{currentIndex == 1 ?
						<div className='contentVideosTexti'>
				
						<ReactPlayer
						width='23rem'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoCristian} />
				   
						</div>: null
				}
				{currentIndex == 2 ?
						<div className='contentVideosTexti'>
				
						<ReactPlayer
						width='23rem'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoDanilo} />
				   
						</div>: null
				}</>:
				<>
						{currentIndexES == 0 ?
						<div className='contentVideosTexti'>
				
						<ReactPlayer
						width='23rem'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoCristianAbril} />
				   
						</div>: null
				}
				
				{currentIndexES == 1 ?
						<div className='contentVideosTexti'>
				
						<ReactPlayer
						width='23rem'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoYeimit} />
				   
						</div>: null
				}
				{currentIndexES == 2 ?
						<div className='contentVideosTexti'>
				
						<ReactPlayer
						width='23rem'
						height='full'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoBonilla} />
				   
						</div>: null
				}
				{currentIndexES == 3 ?
						<div className='contentVideosTexti'>
				
						<ReactPlayer
						width='23rem'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoPeña} />
				   
						</div>: null
				}
				
				</>
					}
					
					{i18n.language == "en" ?
						<div className='desktop:ml-12 bigdesktop:ml-40'>
				
					
						<p className='textTestimonial'>{itemsEnglish[currentIndex].description}</p>
						
						<div className='contentTextNameExpert'>
							<p className='textNameExpert'>{itemsEnglish[currentIndex].name}</p>
							<p className='textPOsicionExpert'>{itemsEnglish[currentIndex].position}</p>
						</div>
						<div className='desktop:flex ml-auto  mobile:mt-8'>
							  <button onClick={prevSlide}>
							  <img src={arrowLeft} alt="Imagen PopUp" className="mt-1 mr-8"/>
							  </button>
							  <button onClick={nextSlide}>
							  <img src={arrowRigth} alt="Imagen PopUp" className=""/>
							  </button>
							</div>
						</div>:
						<div className='desktop:ml-12 bigdesktop:ml-40'>
				
					
						<p className='textTestimonial'>{itemsEspanish[currentIndexES].description}</p>
						
						<div className='contentTextNameExpert'>
							<p className='textNameExpert'>{itemsEspanish[currentIndexES].name}</p>
							<p className='textPOsicionExpert'>{itemsEspanish[currentIndexES].position}</p>
						</div>
						<div className='desktop:flex ml-auto  mobile:mt-8'>
							  <button onClick={prevSlideES}>
							  <img src={arrowLeft} alt="Imagen PopUp" className="mt-1 mr-8"/>
							  </button>
							  <button onClick={nextSlideES}>
							  <img src={arrowRigth} alt="Imagen PopUp" className=""/>
							  </button>
							</div>
						</div>

					}
				
					
					</div>

					

			
			</div></>)
			:(<>
			<div className='mt-16'>
			<p className={windowWidth >= 1025 ? 'titleTeamExperts': 'titleTeamExperts eventRightTimeLineMobile'}>{i18n.t('OurExperienceWhyUs.titleTeams')} {i18n.t('OurExperienceWhyUs.titleTeams2')}</p> 
			</div>
			<div className=" mt-8">

			{ i18n.language == "en" ?
				<>
						{currentIndex == 0 ?
				<div className='ml-4 mr-4'>
				<ReactPlayer
						width={windowWidth >= 1025 ?'23rem':'100%'}
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoJuan} />
				</div>:null}
				{currentIndex == 1 ?
				<div className='ml-4 mr-4'>
				<ReactPlayer
						width={windowWidth >= 1025 ?'23rem':'100%'}
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoCristian} />
				</div>:null}
				{currentIndex == 2 ?
				<div className='ml-4 mr-4'>
				<ReactPlayer
						width={windowWidth >= 1025 ?'23rem':'100%'}
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoDanilo} />
				</div>:null}

				</>:
				<>
						{currentIndexES == 0 ?
						<div className=''>
				
						<ReactPlayer
						width='auto'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoCristianAbril} />
				   
						</div>: null
				}
				
				{currentIndexES == 1 ?
						<div className=''>
				
						<ReactPlayer
						width='auto'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoYeimit} />
				   
						</div>: null
				}
				{currentIndexES == 2 ?
						<div className=''>
				
						<ReactPlayer
						width='auto'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoBonilla} />
				   
						</div>: null
				}
				{currentIndexES == 3 ?
						<div className=''>
				
						<ReactPlayer
						width='auto'
						height='100%'
						className="VideoDiv"
						playing={false}
						controls={true}
						url={videoPeña} />
				   
						</div>: null
				}
				</>
			}

			

				
				<div className='ml-4 mr-4 mt-8'>
					{i18n.language == "en" ?
						<>
							<p className='textTestimonialAcademyMobile'>{itemsEnglish[currentIndex].description}</p>
					
							<div className='contentTextNameExpertMobile'>
								<p className='textNameExpert'>{itemsEnglish[currentIndex].name}</p>
								<p className='textPOsicionExpert mt-4'>{itemsEnglish[currentIndex].position}</p>
							</div>
						</>:
						<>
								<p className='textTestimonialAcademyMobile'>{itemsEspanish[currentIndexES].description}</p>
					
								<div className='contentTextNameExpertMobile'>
									<p className='textNameExpert'>{itemsEspanish[currentIndexES].name}</p>
									<p className='textPOsicionExpert mt-4'>{itemsEspanish[currentIndexES].position}</p>
								</div>
						</>

					}
					

					{i18n.language == "en" ? 
						<div className='desktop:flex ml-auto  mobile:mt-8'>
						<button onClick={prevSlide}>
						<img src={arrowLeft} alt="Imagen PopUp" className="mt-1 mr-8"/>
						</button>
						<button onClick={nextSlide}>
						<img src={arrowRigth} alt="Imagen PopUp" className=""/>
						</button>
					  </div>:
					  <div className='desktop:flex ml-auto  mobile:mt-8'>
					  <button onClick={prevSlideES}>
					  <img src={arrowLeft} alt="Imagen PopUp" className="mt-1 mr-8"/>
					  </button>
					  <button onClick={nextSlideES}>
					  <img src={arrowRigth} alt="Imagen PopUp" className=""/>
					  </button>
					</div>
					}
					

				</div>
			</div></>)
			}
		</div>
	);
};

export default CustomerTestimonials;